import React, { useState, useEffect } from 'react';
import './NewProduct.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
//import { useNavigate } from 'react-router-dom';
export const NewProduct = ({ values, onSubmit, onCancel }) => {
    //const navigate = useNavigate();
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview1, setImagenPreview1] = useState(null);
    const [imagenPreview2, setImagenPreview2] = useState(null);
    const [imagenPreview3, setImagenPreview3] = useState(null);
    const [imagenPreview4, setImagenPreview4] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [titulo, setTitulo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [masVendido, setMasVendido] = useState('');
    const [showInHome, setShowInHome] = useState('');
    const [precio, setPrecio] = useState('');
    const [isImage1Selected, setIsImage1Selected] = useState(false);
    const [isImage2Selected, setIsImage2Selected] = useState(false);
    const [isImage3Selected, setIsImage3Selected] = useState(false);
    const [isImage4Selected, setIsImage4Selected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [categorias, setCategoras] = useState([]);
    const [contacto, setContacto] = useState('');
    const [img1base64, setImg1Base64] = useState('');
    const [img2base64, setImg2Base64] = useState('');
    const [img3base64, setImg3Base64] = useState('');
    const [img4base64, setImg4Base64] = useState('');
    const [isImg1Base64Selected, setIsImg1Base64Selected] = useState(false);
    const [isImg2Base642Selected, setIsImg2Base642Selected] = useState(false);
    const [isImg3Base64Selected, setIsImg3Base64Selected] = useState(false);
    const [isImg4Base64Selected, setIsImg4Base64Selected] = useState(false);

    const toggleModal = () => {

        setModalOpen(!modalOpen);

    };

    const handleImagenChange = (event, imgNum, setImagenPreview, setIsImageSelected) => {

        const file = event.target.files[0];

        if (file) {

            const previewURL = URL.createObjectURL(file);
            
            setImagenPreview(previewURL);
            
            setIsImageSelected(true);

            const reader = new FileReader();
    
            reader.onloadend = () => {

                if(imgNum === 1) {
                    
                    setImg1Base64(reader.result);

                    setIsImg1Base64Selected(true);

                    //console.log(`Image to Base64`);

                    //console.log(img1base64);
                
                } else if(imgNum === 2) {
                    
                    setImg2Base64(reader.result);

                    setIsImg2Base642Selected(true);

                    //console.log(`Image to Base64`);

                    //console.log(img2base64);

                } else if(imgNum === 3) {

                    setImg3Base64(reader.result);

                    setIsImg3Base64Selected(true);

                    //console.log(`Image to Base64`);

                    //console.log(img3base64);

                } else if(imgNum === 4) {
                    
                    setImg4Base64(reader.result);

                    setIsImg4Base64Selected(true);

                    //console.log(`Image to Base64`);

                    //console.log(img4base64);

                }

            };
            
            reader.readAsDataURL(file);
        
        }
    
    };

    const crear = async () => {
        
        const form = document.getElementById("crearForm");
        
        const formData = new FormData(form);
        
        formData.append('contacto', contacto);

        formData.append('imgstr1', img1base64);
        
        formData.append('imgstr2', img2base64);
        
        formData.append('imgstr3', img3base64);
        
        formData.append('imgstr4', img4base64);
        
        const resetForm = () => {
            
            form.reset();
            
            setImagenPreview1(null);
            
            setImagenPreview2(null);

            setIsImage1Selected(false);
            
            setIsImage2Selected(false);
        
        };
        
        setMensaje('');

        if (
        
            !formData.get('descripcion') ||
        
            !formData.get('titulo') ||
        
            !formData.get('categoria') ||
        
            !formData.get('masVendido') ||
        
            !formData.get('precio') ||
        
            !formData.get('imagen1') ||
        
            !formData.get('imagen2') ||
        
            !formData.get('imagen3') ||
        
            !formData.get('imagen4')
        
        ) {
        
            toast.error('Por favor, complete todos los campos correctamente.');
        
            return;
        
        }

        setMensaje('Procesando...');

        try {
        
            const response = await fetch(`${baseURL}/productosPost.php`, {
        
                method: 'POST',
        
                body: formData,
        
                headers: new Headers({

                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),
        
            });

            const data = await response.json();

            if (data.mensaje) {
        
                setMensaje('');
        
                resetForm();
        
                toast.success(data.mensaje);
                //window.location.reload(); //quitar
                //navigate('/dashboard/productos');
            } else if (data.error) {
        
                setMensaje('');
        
                toast.error(data.error);
        
                //console.log(data.error);

            }
        } catch (error) {
        
            console.error('Error:', error);
        
            setMensaje('');
        
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');

        }
    
    };

    const handleCategoriaChange = (e) => {
        
        setCategoria(e.target.value);

    };

    const handleMasVendidoChange = (e) => {
        
        setMasVendido(e.target.value);

    };

    const handleshowInHomeChange = (e) => {
        
        setShowInHome(e.target.value);

    };

    useEffect(() => {

        cargarCategoria();

        getContacto();

    }, []);

    const cargarCategoria = () => {

        fetch(`${baseURL}/categoriasGet.php`, {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                setCategoras(data.categorias || []);

                //console.log(data.categorias);

            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);
    
        } catch (error) {

            setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    return (
        <>
            <form id="crearForm" onSubmit={onSubmit}>
                <div className='flexGrap'>
                    <fieldset>
                        <legend>Título</legend>
                        <input
                            type="text"
                            id="titulo"
                            name="titulo"
                            required
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Precio</legend>
                        <input
                            type="number"
                            id="precio"
                            name="precio"
                            min="0"
                            step="0.01"
                            required
                            value={precio}
                            onChange={(e) => setPrecio(e.target.value)}
                        />
                    </fieldset>
                    <fieldset id='descripcion'>
                        <legend>Descripción</legend>
                        <textarea
                            id="descripcion"
                            name="descripcion"
                            required
                            value={descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                            placeholder="Descripción"
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Categoría</legend>
                        <select
                            id="categoria"
                            name="categoria"
                            value={categoria}
                            onChange={handleCategoriaChange}
                        >
                            <option value="" disabled selected>Selecciona una categoría</option>
                            {
                                categorias.map(item => (
                                    <option value={item?.idCategoria}>{item?.categoria}</option>
                                ))
                            }
                        </select>
                    </fieldset>
                    <fieldset>
                        <legend>Novedades</legend>
                        <select
                            id="masVendido"
                            name="masVendido"
                            value={masVendido}
                            onChange={handleMasVendidoChange}
                        >
                            <option value="si">Sí</option>
                            <option value="no" selected>No</option>

                        </select>
                    </fieldset>
                    <fieldset>
                        <legend>Mostrar en Inicio</legend>
                        <select
                            id="showInHome"
                            name="showInHome"
                            value={showInHome}
                            onChange={handleshowInHomeChange}
                        >
                            <option value="si">Sí</option>
                            <option value="no" selected>No</option>

                        </select>
                    </fieldset>
                    { /* Imagenes */}
                    <fieldset>
                        <legend>Imagen1</legend>
                        <input
                            type="file"
                            id="imagen1"
                            name="imagen1"
                            accept="image/*"
                            onChange={(e) => handleImagenChange(e, 1,setImagenPreview1, setIsImage1Selected)}
                            required
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Imagen2</legend>
                        <input
                            type="file"
                            id="imagen2"
                            name="imagen2"
                            accept="image/*"
                            onChange={(e) => handleImagenChange(e, 2,setImagenPreview2, setIsImage2Selected)}
                            required
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Imagen3</legend>
                        <input
                            type="file"
                            id="imagen3"
                            name="imagen3"
                            accept="image/*"
                            onChange={(e) => handleImagenChange(e, 3,setImagenPreview3, setIsImage3Selected)}
                            required
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Imagen4</legend>
                        <input
                            type="file"
                            id="imagen4"
                            name="imagen4"
                            accept="image/*"
                            onChange={(e) => handleImagenChange(e, 4,setImagenPreview4, setIsImage4Selected)}
                            required
                        />
                    </fieldset>
                    { /* Imagenes */}
                </div>
                {(isImage1Selected || isImage2Selected || isImage3Selected || isImage4Selected) &&
                    <div className='previevCategori'>
                        {isImage1Selected && <img src={imagenPreview1} alt="Vista previa 1" />}
                        {isImage2Selected && <img src={imagenPreview2} alt="Vista previa 2" />}
                        {isImage3Selected && <img src={imagenPreview3} alt="Vista previa 3" />}
                        {isImage4Selected && <img src={imagenPreview4} alt="Vista previa 4" />}
                    </div>
                }
                <div
                    className="swal2-actions"
                    style={{ display: "flex", fontSize: "0.9em" }}
                >
                    {mensaje ? (
                        <button type="button" className='btnLoading' disabled>
                            {mensaje}
                        </button>
                    ) : (
                        <button type="button" onClick={crear} className='btnPost'>
                            Agregar
                        </button>
                    )}
                </div>
            </form>
        </>
    );
}

