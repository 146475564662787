import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link as Anchor } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import baseURL from '../url';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeCompare, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
//import Auth from '../Auth/Auth';
import Login from '../Admin/Login/Login';

export default function Activar() {

    const { id } = useParams();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [activecode, setActivecode] = useState('');
    const [phone, setPhone] = useState('');
    const [phonecode, setPhonecode] = useState('');
    const [modalActiveCodeIsOpen, setModalActiveCodeIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isFocused, setIsFocused] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
    const [otpsend, setOTPSend] = useState(false);

    const setOpenActiveCode = () => {

        setModalActiveCodeIsOpen(true);

        setIsFocused(true);

    };

    const setCloseModalActiveCode = () => {

        setModalActiveCodeIsOpen(false);

        setIsFocused(false);

    };

    const validarUsuario = useCallback(async () => {

        //e.preventDefault();

        if (!executeRecaptcha) {

            console.error("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/verificaUsuario.php?` + new URLSearchParams({

            recaptchaToken: token,

            codigo: activecode

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {

                if (data.success===true) {

                    if(data.activado === true) 

                        setIsActivated(true);

                    toast.success(data.mensaje);

                    /*
                    setTimeout(() => {

                        navigate('/login');

                    }, 2000);
                    */

                } else 

                    toast.error(data.error);

            })
            .catch(error => {

                console.error('Error:', error);

            });
        
    }, [executeRecaptcha,activecode]);

    useEffect(() => {

        //console.log(`Id en activar es ${id}`);

        setActivecode(id);

        //checkParams(searchParams);

        setTimeout(() => {

            if(activecode) {

                validarUsuario();

            }

        }, 1000);

    }, [searchParams, id, activecode, validarUsuario]);

    const checkParams = (params) => {

        //console.log(`Checking Params in URL`);

        //console.log(params); 
        
        if(params.has('code') || id) {

            const code = params.get('code') ? params.get('code') : id;

            //console.log(`This code is ${code}`);

            setActivecode(code);

            //setOpenActiveCode();

        }

    };
    
    const iratienda = () => {

        navigate('/home');

    };

    const enviaOTP = useCallback(async (e) => {

        e.preventDefault();

        if (!executeRecaptcha) {

            console.error("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        if(phone.length === 0) {

            Swal.fire({

                icon: 'error',

                title: 'Oops...',

                text: 'Debe ingresar un número de teléfono',

            });

            return;

        }

        fetch(`${baseURL}/enviaOTP.php?` + new URLSearchParams({

            recaptchaToken: token,

            phone: phone,

            codigo: activecode

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {

                if (data.success===true) {

                    toast.success(data.mensaje);

                    setOTPSend(true);

                } else {

                    toast.error(data.error);

                    if(data.phonestatus!=='Active') {

                        setOTPSend(true);

                        setIsActivated(false);

                    } else 

                        navigate('/login');

                }

            })
            .catch(error => {

                console.error('Error:', error);

            });

    }, [executeRecaptcha, phone, activecode]);

    const VerificaOTPEnviado = useCallback(async (e) => {

        e.preventDefault();

        if (!executeRecaptcha) {

            console.error("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        if(phone.length === 0)

            Swal.fire({

                icon: 'error',

                title: 'Oops...',

                text: 'Debe ingresar un número de teléfono',

            });

        fetch(`${baseURL}/verificaenvioOTP.php?` + new URLSearchParams({

            recaptchaToken: token,

            phone: phone,

            codigo: activecode

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {

                if (data.success===true) {

                    toast.success(data.mensaje);

                    //setOTPSend(true);

                } else 

                    toast.error(data.error);

            })
            .catch(error => {

                console.error('Error:', error);

            });

    }, [executeRecaptcha, phone, activecode]);

    const validaOTP = useCallback(async (e) => {

        e.preventDefault();

        if (!executeRecaptcha) {

            console.error("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/verificaOTP.php?` + new URLSearchParams({

            recaptchaToken: token,

            phonecode: phonecode,

            codigo: activecode

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {

                if (data.success===true) {

                    toast.success(data.mensaje);

                    setTimeout(() => {

                        navigate('/login');

                    }, 2000);

                } else 

                    toast.error(data.error);

            })
            .catch(error => {

                console.error('Error:', error);

            });

    }, [executeRecaptcha, phonecode, navigate]);

    return (
        <div className='AuthContainer'>
            <Anchor >
                <img src="/assets/images/logo_sivendo_192x192.webp" alt="sivendoonline.com" className='logoAtuh' />
            </Anchor>
            { isActivated ? (

                <>
                    <div className='formContain'>
                        <ToastContainer />
                        <h3>Cuenta Activada</h3>
                        <h2>Registrar número telefónico</h2>
                        <form onSubmit={enviaOTP} className='formAuth'>
                            { <div className='inputsAuth'>
                                <label>Número de teléfono</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder='Número de teléfono'
                                />
                            </div> }
                            <button type="submit" className='btnLogin'>
                                Enviar Código <FontAwesomeIcon icon={faPaperPlane} className='icon' />
                            </button>
                        </form>
                        <div className='footerText'>
                            <p>Este Sitio web est&aacute; protegido por Google reCAPTCHA</p>
                            <Anchor to='https://policies.google.com/privacy'>Privacidad</Anchor>
                            <Anchor to='https://policies.google.com/terms'>Condiciones</Anchor>
                        </div>
                    </div>
                </> 

            ) :
            otpsend ? (
                <>
                    <div className='formContain'>
                        <ToastContainer />
                        <h2>Activar número telefónico</h2>
                        <form onSubmit={validaOTP} className='formAuth'>
                            { <div className='inputsAuth'>
                                <label>Ingrese el código recibido:</label>
                                <input
                                    type="text"
                                    id="phonecode"
                                    name="phonecode"
                                    value={phonecode}
                                    onChange={(e) => setPhonecode(e.target.value)}
                                    placeholder='OTP Teléfono'
                                />
                            </div> }
                            <button type="submit" className='btnLogin'>
                                Validar mi número telefónico <FontAwesomeIcon icon={faPhone} className='icon' />
                            </button>
                        </form>
                        <div className='footerText'>
                            <p>Este Sitio web est&aacute; protegido por Google reCAPTCHA</p>
                            <Anchor to='https://policies.google.com/privacy'>Privacidad</Anchor>
                            <Anchor to='https://policies.google.com/terms'>Condiciones</Anchor>
                        </div>
                    </div>
                </> 

            ) : 
            (
                <>
                    <div className='formContain'>
                        <ToastContainer />
                        <h2>Activar Cuenta</h2>
                        <form onSubmit={validarUsuario} className='formAuth'>
                            { /* <div className='inputsAuth'>
                                <label>Código de Activación</label>
                                <input
                                    type="text"
                                    id="activecode"
                                    name="activecode"
                                    value={activecode}
                                    onChange={(e) => setActivecode(e.target.value)}
                                    placeholder='Código de Activación'
                                    readOnly={true}
                                />
                            </div> */ }
                            <button type="submit" className='btnLogin'>
                                Activar mi cuenta <FontAwesomeIcon icon={faCodeCompare} className='icon' />
                            </button>
                        </form>
                        <div className='footerText'>
                            <p>Este Sitio web est&aacute; protegido por Google reCAPTCHA</p>
                            <Anchor to='https://policies.google.com/privacy'>Privacidad</Anchor>
                            <Anchor to='https://policies.google.com/terms'>Condiciones</Anchor>
                        </div>
                    </div>
                </>
            ) /*:
            (
                <>
                    <div className='formContain'>
                        <ToastContainer />
                        <h2>No se puede activar la cuenta</h2>
                        <form onSubmit={iratienda()} className='formAuth'>
                            <div className='inputsAuth'>
                                <label>No debería estar aquí. Haga click para ir a la tienda.</label>
                            </div>
                            <button type="submit" className='btnLogin'>
                                Activar mi cuenta <FontAwesomeIcon icon={faPaperPlane} className='icon' />
                            </button>
                        </form>
                    </div>
                </>
            ) */
            }
        </div>
    );
}
