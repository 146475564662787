import React, { useEffect, useState, useRef, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import './CategoriasData.css'
import 'jspdf-autotable';
import baseURL from '../../url';
import { NewCategoria } from '../NewCategoria/NewCategoria';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function CategoriasData() {
    
    const [categorias, setCategorias] = useState([]);
    
    const [modalVisible, setModalVisible] = useState(false);
    
    const [nuevaCategoria, setNuevaCategoria] = useState('');
    
    const [categoria, setCategoria] = useState({});
    
    const [selectedSection, setSelectedSection] = useState('texto');
    
    const [isFocused, setIsFocused] = useState(false);
    
    const [usuario, setUsuario] = useState([]);
    
    const divReference = useRef(null);

    const MySwal = withReactContent(Swal);

    const [imgbase64, setImgBase64] = useState('');

    const [isImageSelected, setIsImageSelected] = useState(false);

    const [imagenPreview, setImagenPreview] = useState(null);

    useEffect(() => {

        if(JSON.parse(localStorage.getItem('usuarios'))!== null) {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            setUsuario(dataUser);

            //console.log(`dataUser es ${dataUser}`);

        }

        //divReference.current.focus();
        setTimeout(() => {

            cargarCategoria();

        }, 1000);

    }, []);

    const setFocus = () => {

        setIsFocused(!isFocused);

        //console.log("Is Focused?");
        //console.log(isFocused);

        if(isFocused){

            cargarCategoria();

        }

    }

    const handleImagenChange = (event, imgNum, setImagenPreview, setIsImageSelected) => {

        const file = event.target.files[0];

        if (file) {

            const previewURL = URL.createObjectURL(file);
            
            setImagenPreview(previewURL);
            
            setIsImageSelected(true);

            const reader = new FileReader();
    
            reader.onloadend = () => {

                setImgBase64(reader.result);

                //console.log(`Image to Base64`);

                //console.log(imgbase64);

            };
            
            reader.readAsDataURL(file);
        
        }
    
    };

    const cargarCategoria = () => {

        fetch(`${baseURL}/categoriasGet.php`, {
        
            method: 'GET',
        
            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
        
            .then(data => {
        
                setCategorias(data.categorias || []);
        
                //console.log(data.categorias);

                //console.log(`Rol de usuario es ${usuario[0].rol}`);
        
            })
        
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const eliminarCategoria = (idCategoria) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/categoriaDelete.php?idCategoria=${idCategoria}`, {
                    method: 'DELETE',
                    headers: new Headers({

                        Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
        
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarCategoria();
                    })
                    .catch(error => {
                        console.error('Error al eliminar contacto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setCategoria(item);
        setNuevaCategoria(item.categoria);

        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleUpdateText = (idCategoria) => {
        const payload = {

            categoria: nuevaCategoria !== '' ? nuevaCategoria : categoria.categoria,
            
            imagen: imgbase64!== '' ? imgbase64 : categoria.imagen,

        };

        fetch(`${baseURL}/categoriaPut.php?idCategoria=${idCategoria}`, {
            
            method: 'PUT',

            headers: new Headers({

                'Content-Type': 'application/json',

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
            
            /*headers: {
            
                'Content-Type': 'application/json',
            
            },*/
            
            body: JSON.stringify(payload),
        
        })
            .then(response => response.json())
        
            .then(data => {
        
                if (data.error) {
        
                    Swal.fire(
        
                        'Error!',
        
                        data.error,
        
                        'error'
        
                    );
        
                    //console.log(idCategoria)
        
                } else {
        
                    Swal.fire(
        
                        'Editado!',
        
                        data.mensaje,
        
                        'success'
        
                    );
        
                    cargarCategoria();
        
                    cerrarModal();
        
                }
        
            })
        
            .catch(error => {
        
                //console.log(error.message);
        
                toast.error(error.message);
        
            });
    
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    const newCategory = values => {

        return new Promise((resolve, reject) => {
            MySwal.fire({
            title: "Agregar Categoría",
            html: (
                <>
                    <div className="modal">
                        <div className="modal-content"> 
                            <div className='deFlexBack'>
                                <h4>Agregar Categoría</h4>
                                <span className="close" onClick={() => Swal.close() } >&times;</span>
                            </div>
                            <NewCategoria
                                values={values}
                                onSubmit={() => {
                                    resolve();
                                    Swal.close();
                                }}
                                onCancel={() => Swal.close()}
                            />
                        </div>
                    </div>
                </>
            ),
            didClose: () => {
                cargarCategoria();
                reject()
            },
                showConfirmButton: false
            });
        });
    };

    const showModal = () => {
        newCategory({
        categoria: "",
        })
        .then(values => console.log(values))
        .catch(() => console.log("Modal closed"));
    };

    return (
        <div tabIndex="0" ref={divReference} onFocus={setFocus} onBlur={setFocus}>
            <ToastContainer />
            <button className='btnSave' onClick={showModal}><FontAwesomeIcon icon={faPlusCircle} /> Agregar</button>

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>

                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Categoria</legend>
                                    <input
                                        type="text"
                                        value={nuevaCategoria !== '' ? nuevaCategoria : categoria.categoria}
                                        onChange={(e) => setNuevaCategoria(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Imagen</legend>
                                    <input
                                        type="file"
                                        id="imagen"
                                        name="imagen"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, 1,setImagenPreview, setIsImageSelected)}
                                        required
                                    />
                                </fieldset>
                                <fieldset>
                                {(isImageSelected) &&
                                <div className='previevCategori'>
                                    {isImageSelected && <img src={imagenPreview} alt="Vista previa" />}
                                </div>
                            }
                                </fieldset>
                            </div>
                            <button className='btnPost' onClick={() => handleUpdateText(categoria.idCategoria)} >Actualizar</button>
                        </div>

                    </div>
                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id Categoria</th>
                            <th>Categoria</th>
                            <th>Imagen</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categorias.map(item => (
                            <tr key={item.idCategoria}>
                                <td>{item.idCategoria}</td>
                                <td>{item.categoria}</td>
                                <td><img src={item.imagen} alt="imagen" /></td>
                                <td>
                                    { 
                                        usuario[0].rol === 'admin' 
                                        ? (
                                            <>
                                                <button className='eliminar' onClick={() => eliminarCategoria(item.idCategoria)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                                <button className='editar' onClick={() => abrirModal(item)}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </> 
                                        )
                                        : 
                                            null
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};
