import React, { useEffect, useState, useRef, useCallback } from 'react';
//import logo from '../../images/logo.png'
import './Profile.css'
import { Link as Anchor } from 'react-router-dom';
import baseURL from '../url';
import { useParams } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
export default function Profile() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { id } = useParams();

    const [contactos, setContactos] = useState([]);

    useEffect(() => {
        cargarContacto(id);

    }, []);

    //console.log(`Profile Store id ${id}`);

    const cargarContacto = useCallback(async (contacto) => {

        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/contactoGet.php?` + new URLSearchParams({

            store: contacto,

            recaptchaToken: token,

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse()[0] || []);

            })
            .catch(error => console.error('Error al cargar contactos:', error));

    },[executeRecaptcha]);

    return (
        <div className='profileContain'>
            <img src="/assets/images/logo.png" alt="" />
            <h2>{contactos.nombre}</h2>
            <div className='socials'>
                <Anchor to={contactos.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                <Anchor to={`tel:${contactos.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                <Anchor to={contactos.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
            </div>
            <div className='profileText'>
                <Anchor to={`mailto:${contactos.email}`} target="_blank">{contactos.email}</Anchor>
                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contactos.direccion)}`} target="_blank">{contactos.direccion}</Anchor>

            </div>
        </div>
    )
}
