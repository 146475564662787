import React, { useEffect, useState, useCallback } from 'react';
import './Footer.css'
import { Link as Anchor } from 'react-router-dom';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function Footer() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { id } = useParams();
    
    const [productos, setProductos] = useState([]);
    
    const [categorias, setCategorias] = useState([]);
    
    const [contactos, setContactos] = useState([]);
    
    useEffect(() => {

        ////console.log(`Footer Store id ${id}`);

        const cargarProductos = async (store) => {

            if (!executeRecaptcha) {
    
                //console.log("Execute recaptcha not yet available");
    
                return;
    
            }
    
            const token = await executeRecaptcha('login');
    
            //console.log('Footer loading productos');
    
            fetch(`${baseURL}/productosGet.php?` + new URLSearchParams({
    
                store: store,
    
                recaptchaToken: token,
    
            }), {
    
                method: 'GET',
    
            })
                .then(response => response.json())
    
                .then(data => {
    
                    //console.log('Get Productos');
    
                    //console.log(data.productos);
    
                    const categoriasMap = new Map();
    
                    data.productos.forEach(producto => {
    
                        const categoria = producto.categoria;
    
                        if (categoriasMap.has(categoria)) {
    
                            categoriasMap.get(categoria).push(producto);
    
                        } else {
    
                            categoriasMap.set(categoria, [producto]);
    
                        }
    
                    });
    
                    const categoriasArray = Array.from(categoriasMap, ([categoria, productos]) => ({ categoria, productos }));
    
                    setCategorias(categoriasArray?.slice(0, 3));
    
                    setProductos(data.productos?.slice(0, 3));
    
                })
    
                .catch(error => console.error('Error al cargar productos:', error));
    
        };

        cargarProductos(id);

        const cargarContacto = async (store) => {

            if (!executeRecaptcha) {
    
                //console.log("Execute recaptcha not yet available");
    
                return;
    
            }
    
            const token = await executeRecaptcha('login');
    
            //console.log('Footer loading contacto');
    
            fetch(`${baseURL}/contactoGet.php?` + new URLSearchParams({
    
                store: store,
    
                recaptchaToken: token,
    
            }), {
    
                method: 'GET',
    
            })
                .then(response => response.json())
    
                .then(data => {
    
                    //console.log('Get Contacto');
    
                    //console.log(data.contacto);
    
                    setContactos(data.contacto.reverse()[0] || []);
    
                })
                .catch(error => console.error('Error al cargar contactos:', error));
    
        };

        cargarContacto(id);

    }, [executeRecaptcha,id]);

    return (
        <div className='FooterContain'>
            <div className='footerText'>
                <Anchor to='' target="_blank">   <img src="/assets/images/logo.png" alt="logo" /></Anchor>

                <div className='socials'>
                    <Anchor to={contactos.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                    <Anchor to={`tel:${contactos.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                    <Anchor to={contactos.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                    { /* <Anchor to={`/dashboard`} >
                        <FontAwesomeIcon icon={faUser} />
                    </Anchor> */ }
                </div>
            </div>
            <div className='footerText'>
                <Anchor to={`mailto:${contactos.email}`} target="_blank">{contactos.email}</Anchor>
                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contactos.direccion)}`} target="_blank">{contactos.direccion}</Anchor>
                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contactos.localidad)}`} target="_blank">{contactos.localidad}</Anchor>

            </div>
            <div className='footerText'>
                {
                    productos?.map(item => (
                        <Anchor to='' >{item?.titulo?.slice(0, 33)}</Anchor>
                    ))
                }

            </div>
            <div className='footerText'>
                {
                    categorias?.map(item => (
                        <Anchor to='' >{item?.categoria?.slice(0, 30)}</Anchor>
                    ))
                }
            </div>
            <div className='footerText'>
                <p>Este Sitio web est&aacute; protegido por Google reCAPTCHA</p>
                <Anchor to='https://policies.google.com/privacy'>Privacidad</Anchor>
                <Anchor to='https://policies.google.com/terms'>Condiciones</Anchor>
            </div>
        </div>
    )
}
