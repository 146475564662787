import React, { useEffect, useState } from 'react';
import './NewCodigo.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';

//export default function NewCodigo() {
export const NewCodigo = ({ values, onSubmit, onCancel }) => {
    
    const [mensaje, setMensaje] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [descuento, setDescuento] = useState(0);
    const [inicia, setInicia] = useState('');
    const [vence, setVence] = useState(0);
    const [disponible, setDisponible] = useState(0);
    const [codigo, setCodigo] = useState('');
    const [contacto, setContacto] = useState('');
    const [generado, setGenerado] = useState(false);
    const [existe, setExiste] = useState(false);

    const toggleModal = () => {
        setCodigo('');
        setMensaje('');
        setDescuento(0);
        setModalOpen(!modalOpen);
    };

    const checaCodigo = (codigo) => {

        fetch(`${baseURL}/codigosGet.php?` + new URLSearchParams({
            codigo: codigo
        }), {
            method: 'GET',
            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
        })
            .then(response => response.json())
            .then(data => {

                if (data.codigos)

                    setExiste(true);
            })
            .catch(error => {

                console.error('Error:', error);

            });

    };

    const generarCodigoAleatorio = () => {

        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

        let codigoAleatorio = '';

        for (let i = 0; i < 6; i++) {

            codigoAleatorio += caracteres.charAt(Math.floor(Math.random() * caracteres.length));

        }

        setCodigo(codigoAleatorio);

        //return codigoAleatorio;

    };

    const crear = async () => {

        //const codigoAleatorio = generarCodigoAleatorio();

        const formData = new FormData();

        formData.append('contacto', contacto);
        
        formData.append('codigo', codigo);
        
        formData.append('descuento', descuento);
        
        formData.append('maxuso', disponible);
        
        formData.append('inicia', inicia);
        
        formData.append('vence', vence);

        if (
            !formData.get('codigo') ||
            !formData.get('descuento') ||
            !formData.get('maxuso') ||
            !formData.get('inicia') ||
            !formData.get('vence') 
        ) {

            toast.error('Por favor, complete todos los campos correctamente.');

            return;

        }

        setMensaje('Procesando...');

        try {

            const response = await fetch(`${baseURL}/codigosPost.php`, {

                method: 'POST',

                body: formData,

                headers: new Headers({

                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),

            });

            const data = await response.json();

            if (data.mensaje) {
                
                setMensaje('');
                
                toast.success(data.mensaje);
                
                //toggleModal();
                
                //window.location.reload();

            } else if (data.error) {
                
                setMensaje('');
                
                toast.error(data.error);

            }

        } catch (error) {

            console.error('Error:', error);

            setMensaje('');

            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');

        }

    };

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));
    
            setContacto(dataUser[0].idUsuario);
    
        } catch (error) {

            setContacto(0);
    
            //toast.error(error.message);
    
        }

    };

    useEffect(() => {

        getContacto();

        if(!generado) {

            generarCodigoAleatorio();

            checaCodigo(codigo);

            if( existe !== true ) {

                setGenerado(true);

            }

        }

    }, [existe, codigo, generado]);

    return (
        <>
            <form id="crearForm" onSubmit={onSubmit}>
                <div className='flexGrap'>
                <fieldset>
                    <legend>Codigo</legend>
                    <input
                        type='text'
                        name='codigo'
                        value={codigo}
                        readOnly
                    />
                </fieldset>
                <fieldset>
                    <legend>Descuento ($)</legend>
                    <input
                        type='number'
                        name='descuento'
                        value={descuento}
                        onChange={(e) => setDescuento(e.target.value)}
                    />
                </fieldset>
                <fieldset>
                    <legend>disponible</legend>
                    <input
                        type='number'
                        name='disponible'
                        value={disponible}
                        onChange={(e) => setDisponible(e.target.value)}
                    />
                </fieldset>
                <fieldset>
                    <legend>Inicia</legend>
                    <input
                        type='date'
                        name='inicia'
                        value={inicia}
                        onChange={(e) => setInicia(e.target.value)}
                    />
                </fieldset>
                <fieldset>
                    <legend>Vence (días)</legend>
                    <input
                        type='number'
                        name='vence'
                        value={vence}
                        onChange={(e) => setVence(e.target.value)}
                    />
                </fieldset>
                </div>
                <div
                    className="swal2-actions"
                    style={{ display: "flex", fontSize: "0.9em" }}
                >
                    {mensaje ? (
                        <button type="button" className='btnLoading' disabled>
                            {mensaje}
                        </button>
                    ) : (
                        <button type="button" onClick={crear} className='btnPost'>
                            Agregar
                        </button>
                    )}
                </div>
            </form>
        </>
    );
}
