import React, { useEffect, useState, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import { Link as Anchor } from 'react-router-dom';
//import logo from './assets/images/logo.png';
import baseURL from '../url';
import 'swiper/swiper-bundle.css';
import Profile from '../Profile/Profile';
import Stores from '../Stores/Stores';
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faSignIn, faSignOut, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ButonInstallAppNav from '../Admin/ButonInstallAppNav/ButonInstallAppNav';
import Logout from '../Admin/Logout/mainLogout';
import Notifications from '../Admin/Notifications/Notifications';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';

import { useGeolocation } from '@uidotdev/usehooks';

export default function Navbar() {

    const navigate = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [isOpen, setIsOpen] = useState(false);
    
    const [images, setImages] = useState([]);
    
    const [loading, setLoading] = useState(true);
    
    const [usuario, setUsuario] = useState(null);

    const [location, setLocation] = useState(null);
  
    const [weather, setWeather] = useState(null);

    useEffect(() => {

        getContacto();

        cargarBanners();
        
    }, [executeRecaptcha]);

    const handleLocationClick = () => {

        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(success, error);

        } else {

            console.log("Geolocation not supported");

        }
    
    }

    const  success = (position) => {
    
        const latitude = position.coords.latitude;
    
        const longitude = position.coords.longitude;
    
        setLocation({ latitude, longitude });
    
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        // Make API call to OpenWeatherMap
        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=e047a78e1b74300b7afb2e110af541b9&units=metric`)
            
            .then(response => response.json())
            
            .then(data => {
            
                setWeather(data);
            
                console.log(data);

            })
            
            .catch(error => console.log(error));

        }

    const error = () => {

        console.log("Unable to retrieve your location");

    }

    const cargarBanners = useCallback(async () => {
        //const cargarBanners = () => {
        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/bannersGet.php?` + new URLSearchParams({

            recaptchaToken: token,

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {

                const bannerImages = data.banner.map(banner => banner.imagen);
                
                setImages(bannerImages);
                
                setLoading(false);
            
            })
            .catch(error => {
            
                console.error('Error al cargar productos:', error)

            });
    }, [executeRecaptcha]);

    const getContacto = () => {

        try {

            if(localStorage.getItem('usuarios') !== null){

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                //console.log(dataUser);

                //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
        
                setUsuario(dataUser[0].nombre);

            }
    
        } catch (error) {
    
            console.error('Error:', error.message);
    
        }

    };

    const authUser = () => {
    
        navigate('/auth/user');

    }

    return (
        <header>
            <Location />
            <nav>
                <div className='deFLexNavs'>
                    <BrowserView>
                        <Anchor to={`/`} className='logo'>
                            <img src="/assets/images/logo.webp" alt="logo" />
                        </Anchor>
                    </BrowserView>
                    <MobileView>
                        <Anchor to={`/`} className='logo-mobile'>
                            <img src="/assets/images/logo.png" alt="logo" />
                        </Anchor>
                    </MobileView>
                    <Anchor onClick={ () => handleLocationClick() } className='btn-nav'>
                        <FontAwesomeIcon icon={faLocation} className='icon' />
                    </Anchor>
                    <ButonInstallAppNav />
                    {/*<Anchor to={`/auth/user`} className='btn-sesion' >*/}
                    <Anchor onClick={ () => authUser() } className='btn-sesion' >
                        { (usuario ) 
                            ?   <>
                                <FontAwesomeIcon icon={faUserCircle} className='icon'/> 
                                { usuario.charAt(0) }
                                </>
                            : <FontAwesomeIcon icon={faUser} className='icon' />
                        }
                        { /*
                            <FontAwesomeIcon icon={faUser} className='icon' />
                                { (usuario) ? usuario.charAt(0) : 'V'}
                        */ 
                        }
                        { /* <FontAwesomeIcon icon={faUser} /> {usuario ? usuario : ''} */ }
                    </Anchor>
                    <Notifications />
                    {
                        (usuario) 
                        ? <Logout /> 
                        : ''
                    }
                    { /* <div className={`nav_toggle  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */ }
                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        {loading ? (
                            <div className='loadingBanner'>

                            </div>

                        ) : (

                            <div className='fondo'>
                                <img src={images[0]} alt={`imagen`} />
                                <Profile />
                            </div>

                        )}

                    </div>
                </Modal>
            </nav>
        </header>
    );
}

/*function Location() {
  const state = useGeolocation();

  if (state.loading) {
    return <p>Para mostrar los negocios a su alrededor, se requieren permisos de ubicación</p>;
  }

  if (state.error) {
    return <p>Es requerido que permita la ubicación en su dispositivo.</p>;
  }

  //console.log(state);

  return <div state={ (state.loading) ? `<p className="text-info fw-bolder">Verificando permisos de ubicación.</p>` : `< className="text-danger fw-bolder fst-italic">Es requerido que permita la ubicación en su dispositivo.</>` } />;

}*/

function Location() {

    const state = useGeolocation();

    const guardaUbicacion = (state) => {

        if(localStorage.getItem('ubicacion')!==null)

            localStorage.removeItem('ubicacion');

        localStorage.setItem('ubicacion',JSON.stringify(state));

    }

    ////console.log(state);

    return (
        <>
            {
                (state.loading === false) ? (
                    <div state={guardaUbicacion(state)} />
                ) : (
                    <p className="text-info fw-bolder"></p>
                )
            }
        </>
    );

}
