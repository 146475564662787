import IndexLayout from "../Layouts/IndexLayout";
import MainLayout from "../Layouts/MainLayout";
import PageLayout from "../Layouts/PageLayout";
import { createBrowserRouter } from "react-router-dom";
import Productos from '../Pages/Productos/Productos';
import Pedidos from '../Pages/Pedidos/Pedidos';
import Store from '../Pages/Store/Store';
//import Store from '../Components/Store/Store';
import Usuarios from '../Pages/Usuarios/Usuarios';
import Banners from "./Banners/Banners";
import Main from "./Main/Main";
import Contacto from "./Contacto/Contacto";
import Categorias from "./Categorias/Categorias";
import Activar from "../Components/Activar/Activar";
import Codigos from "./Codigos/Codigos";
import InfoUser from "../Components/InfoUser/InfoUser";
import Login from "../Components/Admin/Login/Login";
import Logout from "../Components/Admin/Logout/Logout";
import Auth from "../Components/Auth/Auth";
import Calendar from "../Components/Calendar/Calendar";
import GeolocationExample from "../Components/Admin/InfoUser/WactchGeolocation";
import Notifications from "../Components/Admin/Notifications/Notifications";
export const router = createBrowserRouter([

    {
        path: "/home",
        element: <IndexLayout />,
    },

    {
        path: "/watch",
        element: <GeolocationExample />,
    },

    {
        path: "/login",
        element: <Auth />,
    },

    {
        path: "/activar/:id",
        element: <Activar />,
    },

    {
        path: "/store/:id",
        element: <Store />,
    },

    {
        path: "/calendar",
        element: <Calendar />,
    },

    {
        path: "/calendar/:id",
        element: <Calendar />,
    },

    {
        path: "/",
        element: <IndexLayout />,
        children: [
            {
                path: `/producto/:id`,
                element: <IndexLayout />,
            },
        ],

    },

    {
        element: <PageLayout />,
        children: [
            {
                path: `/auth/user`,
                element: <InfoUser />,
            },

        ],
    },
    
    {
        element: <MainLayout />,
        children: [
            {
                path: `/dashboard`,
                element: <Main />,
            },
            {
                path: `/dashboard/pedidos`,
                element: <Pedidos />,
            },
            {
                path: `/dashboard/productos`,
                element: <Productos />,
            },
            {
                path: `/dashboard/usuarios`,
                element: <Usuarios />,
            },
            {
                path: `/dashboard/banners`,
                element: <Banners />,
            },
            {
                path: `/dashboard/contacto`,
                element: <Contacto />,
            },
            {
                path: `/dashboard/categorias`,
                element: <Categorias />,
            },
            {
                path: `/dashboard/codigos`,
                element: <Codigos />,
            },
            {
                path: `/dashboard/notificaciones`,
                element: <Notifications />,
            },
        ],
    },


]);
