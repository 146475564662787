import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import baseURL from '../../url';
import './BannerData.css';
import withReactContent from 'sweetalert2-react-content';
import { NewBanner } from '../NewBanner/NewBanner';
//import CardsBanner from '../BannerData/cardsBanner';

export default function BannerData() {
    const [banners, setBanners] = useState([]);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');
    const MySwal = withReactContent(Swal);
    useEffect(() => {
        cargarBanners();

    }, []);
    
    const getContacto = () => {

        var dataContacto = 0;

        try {

            if(JSON.parse(localStorage.getItem('usuarios'))!== null) {

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                setContacto(dataUser[0].idUsuario);

                setRol(dataUser[0].rol);

                dataContacto = (dataUser[0].rol !== 'admin') ? dataUser[0].idUsuario : 0;

            }

            return dataContacto;
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const cargarBanners = () => {

        const thisContacto = getContacto();

        fetch(`${baseURL}/bannersGet.php?` + new URLSearchParams({

            contacto: thisContacto

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                setBanners(data.banner || []);

                //console.log(data.banner)

            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const eliminarBanner = (idBanner) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/bannerDelete.php?idBanner=${idBanner}`, {
                    method: 'DELETE',
                    headers: new Headers({

                        Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
        
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarBanners();
                    })
                    .catch(error => {
                        console.error('Error al eliminar la Producto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const nuevoBanner = values => {

        return new Promise((resolve, reject) => {
            MySwal.fire({
            title: "Agregar Banner",
            html: (
                <>
                    <div className="modal">
                        <div className="modal-content"> 
                            <div className='deFlexBack'>
                                <h4>Agregar Banner</h4>
                                <span className="close" onClick={() => Swal.close() } >&times;</span>
                            </div>
                            <NewBanner
                                values={values}
                                onSubmit={() => {
                                    resolve();
                                    Swal.close();
                                }}
                                onCancel={() => Swal.close()}
                            />
                        </div>
                    </div>
                </>
            ),
            didClose: () => {
                getContacto();
                cargarBanners(contacto,rol);
                reject()
            },
                showConfirmButton: false
            });
        });

    };

    const showModal = () => {
        nuevoBanner({
            imagen1:""
        })
        .then(values => console.log(values))
        .catch(() => console.log("Modal closed"));
    };

    return (
        <div className='BannerContainer'>
            { /*<NewBanner /> */ }
            <button className='btnSave' onClick={showModal}><FontAwesomeIcon icon={faPlusCircle} /> Agregar</button>
            <div className='BannerWrap'>
                {
                    banners.map(item => (
                        <div className='cardBanner'>
                            <img src={item.imagen} alt="banner" />
                            <button className='btnBannerDelete' onClick={() => eliminarBanner(item.idBanner)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    ))
                }

            </div>
           {/* <CardsBanner /> */ }
        </div>
    )
}
