import React, { useEffect, useState } from 'react';
import {fab, faAndroid, faAppStoreIos, faChrome, faWpexplorer } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ButonInstallAppNav.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function ButonInstallAppNav() {
//const ButonInstallAppNav = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = e => {
        e.preventDefault();
        //console.log("we are being triggered :D");
        setSupportsPWA(true);
        setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
        
    }, []);

    const onClick = evt => {
        evt.preventDefault();
        if (!promptInstall) {
        return;
        }
        promptInstall.prompt();
    };
    if (!supportsPWA) {
        return null;
    }

    return (
        <>
            <BrowserView>
                <div className='btn-sesion'>
                    <button className='icon' onClick={onClick} title="Instalar">
                        <FontAwesomeIcon icon={faWpexplorer}/>
                    </button>
                </div>
            </BrowserView>
            <MobileView>
                <div className='btn-sesion'>
                    <button className='icon' onClick={onClick} title="Instalar">
                       {
                            window?.navigator?.userAgentData.platform === 'Android' ? <FontAwesomeIcon icon={faAndroid} />  : <FontAwesomeIcon icon={faAppStoreIos} />
                       } 
                    </button>
                </div>
            </MobileView>
            { /* <button 
                    className='btnInstall'
                    id="setup_button"
                    aria-label="Install app"
                    title="Install app"
                    onClick={onClick} 
                >
                    Instalar acceso
                </button> */ }
        </>
    );
};

//export default ButonInstallAppNav;
