import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, } from 'react-router';
import baseURL from '../url';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Modal from 'react-modal';

export default function RegisterForm() {
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [phone, setPhone] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [mensaje2, setMensaje2] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [formularioEnviado, setFormularioEnviado] = useState(false);
    const [rol, setRol] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [phoneOTP, setPhoneOTP] = useState('');
    const [statusUser, setStatusUser] = useState('');
    const [statusUserClass, setStatusUserClass] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [permission, setPermission] = useState(null);
    const navigate = useNavigate();

    //const [password, setPassword] = useState("");
    const [passwordLength, setPasswordLength] = useState(15);
    const [useSymbols, setUseSymbols] = useState(true);
    const [useNumbers, setUseNumbers] = useState(true);
    const [useLowerCase, setUseLowerCase] = useState(true);
    const [useUpperCase, setUseUpperCase] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleRecaptchaChange = (token) => {

        ////console.log(token);

        setRecaptchaToken(token);

    };

    const checkPermission = async () => { 

        try { 
            
            const permissionStatus = await navigator.permissions.query({ name: 'geolocation' }); 
            
            setPermission(permissionStatus.state); 
        
        } catch (error) { 
            
            console.error('Error checking permission:', error); 
        
        } 
    
    }; 
  
    const validate = (value) => { 
  
        if (validator.isStrongPassword(value, { 
            
            minLength: 12, minLowercase: 1, 
            
            minUppercase: 1, minNumbers: 1, minSymbols: 1 
        
        })) { 
            
            setErrorMessage('Es buena Contraseña');
            
            setContrasena(value);
        
        } else { 
            
            setErrorMessage('No es recomedable'); 

            setContrasena(value);
        
        } 
    
    } 

    const generatePassword = () => {

        let charset = "";
        
        let newPassword = "";

        if (useSymbols) charset += "!@#$%^&*()";
        
        if (useNumbers) charset += "0123456789";
        
        if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
        
        if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (let i = 0; i < passwordLength; i++) {
        
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        
        }

        setContrasena(newPassword);
    
    };

    const copyToClipboard = () => {
        
        const el = document.createElement("textarea");
        
        el.value = contrasena;
        
        document.body.appendChild(el);
        
        el.select();
        
        document.execCommand("copy");
        
        document.body.removeChild(el);
        
        setSuccessMessage("Password copied to clipboard!");
        
        setTimeout(() => setSuccessMessage(""), 2000);
        
    };

    useEffect(() => {

        generatePassword();

    }, [passwordLength, useSymbols, useNumbers, useLowerCase, useUpperCase]);

    const saveUserToLocalStorage = (user) => {

        /*//console.log('User antes de local storage');

        //console.log(user);

        if(!user) 
        
            return;

        localStorage.setItem('usuarios', JSON.stringify(user));

        //console.log('User despues de local storage');
        
        //console.log(user);

        return;

        */

        // Obtener usuarios existentes del localStorage
        const existingUsers = JSON.parse(localStorage.getItem('usuarios')) || [];

        // Buscar si ya existe un usuario con el mismo idUsuario
        const existingUserIndex = existingUsers.findIndex(u => u.idUsuario === user.idUsuario);

        // Si existe, reemplazar; de lo contrario, agregar a la lista
        if (existingUserIndex !== -1) {

            existingUsers[existingUserIndex] = user;

        } else {

            existingUsers.push(user);

        }

        // Guardar la lista actualizada en localStorage
        localStorage.setItem('usuarios', JSON.stringify(existingUsers));
        //console.log('usuarios guardados', existingUsers)
    };
    
    const registrarUsuario = useCallback(async (e) => {
        //const registrarUsuario = async (e) => {
        
        e.preventDefault();

        if (!executeRecaptcha) {

            ////console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');
        
        setMensaje2('Procesando...')
        
        try {

            const pushNotData = (localStorage.getItem('pushNotData')!==null) ? JSON.parse(localStorage.getItem('pushNotData')) : '';

            const pushNotId = (pushNotData) ? pushNotData.id : '';
            
            const formData = new FormData();

            formData.append('pushNotId', pushNotId);
            
            formData.append('nombre', nombre);
            
            formData.append('email', email);

            formData.append('emailCode', emailCode);

            formData.append('phone', phone);

            formData.append('phoneCode', phoneOTP);
            
            formData.append('contrasena', contrasena);
            
            formData.append('rol', rol);

            formData.append('recaptchaToken', token);

            const response = await fetch(`${baseURL}/registroPost.php`, {
                
                method: 'POST',
                
                body: formData,
            
            });

            if (response.ok) {
                
                const data = await response.json();
                
                if (data.mensaje) {
                    
                    setMensaje(data.mensaje);
                    
                    toast.success(data.mensaje);

                    saveUserToLocalStorage(data.usuario);
                    
                    setMensaje2('')
                    
                    setTimeout(() => {
                        
                        navigate("/");
                    
                    }, 2000);
                
                } else if (data.error) {
                    
                    setError(data.error);
                    
                    toast.error(data.error);
                    
                    setMensaje2('')
                    
                    //setFormularioEnviado(false);
                
                }
            
            } else {
                
                setMensaje2('');
                
                throw new Error('Error en la solicitud al servidor');
            
            }
        
        } catch (error) {
            
            console.error('Error:', error.message);
            
            toast.error(error.message);
            
            setMensaje2('');
        
        }
    
    }, [executeRecaptcha, nombre, email, phone, contrasena, rol, navigate]);

    const validarUsuario = useCallback(async (e) => {
        
        e.preventDefault();

        if (!executeRecaptcha) {

            ////console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');
        
        setMensaje2('Procesando...')
        
        try {
            
            const formData = new FormData();
            
            formData.append('nombre', nombre);
            
            formData.append('email', email);
            
            formData.append('phone', phone);

            formData.append('recaptchaToken', token);

            const response = await fetch(`${baseURL}/validarUsuario.php`, {
                
                method: 'POST',
                
                body: formData,
            
            });

            if (response.ok) {
                
                const data = await response.json();
                
                if (data.mensaje) {

                    setMensaje(data.mensaje);

                    setStatusUser(data.phonestatus);
                    
                    setMensaje2('');

                    console.log(data);

                    if(data.phonestatus === 'Pending') {

                        setStatusUserClass('text-success');

                        setFormularioEnviado(true);

                        toast.success(data.mensaje);

                    } else {

                        setStatusUserClass('text-info');

                        toast.info(data.mensaje);

                    }
                    
                    /*
                    setTimeout(() => {
                        
                        navigate("/home");
                    
                    }, 2000);
                    */
                
                } else if (data.error) {

                    setStatusUserClass('text-danger');
                    
                    setError(data.error);
                    
                    toast.error(data.error);
                    
                    setMensaje2('')
                    
                    setFormularioEnviado(false);
                
                }
            
            } else {
                
                setMensaje2('');
                
                throw new Error('Error en la solicitud al servidor');
            
            }
        
        } catch (error) {
            
            console.error('Error:', error.message);
            
            toast.error(error.message);
            
            setMensaje2('');
        
        }
    
    }, [executeRecaptcha, nombre, email, phone]);

    const handleChangePhone = (e) => {

        const thisPhone = e.target.value;

        setPhone(thisPhone);

        validatePhoneNumber(thisPhone);

    };

    const validatePhoneNumber = (number) => {

        const phoneRegex = /^[0-9]{10}$/; // Adjust the regex according to your requirements

        setIsValidPhone(phoneRegex.test(number));

    };

    const authGeoLocation = () => {
        
        if (navigator.geolocation) {
        
            navigator.geolocation.getCurrentPosition(showPosition, handleGeoError);
        
        } else {
        
            ////console.log("Geolocation is not supported by this browser.");
        
        }
    
    };

    const showPosition = (position) => {
    
        ////console.log("Latitude: " + position.coords.latitude + " Longitude: " + position.coords.longitude);
    
    };

    const handleGeoError = (error) => {
    
        if (error.code === error.PERMISSION_DENIED) {
    
            setModalIsOpen(true);

            checkPermission();
    
        }
    
    };

    return (
        <>
            <div className='formContain'>
                <ToastContainer />
                {
                    formularioEnviado ? (
                        <>
                            <h2>Validar</h2>
                            {
                                mensaje ? <p class={statusUserClass}>{mensaje}</p> : null
                            }
                            {
                                error ? <p>{error}</p> : null
                            }
                            <form onSubmit={registrarUsuario} className='formAuth'>
                                <div className='inputsAuth'>
                                    <label htmlFor="emailCode">Código Email:</label>
                                    <input
                                        type="emailCode"
                                        id="Code"
                                        name="Code"
                                        value={emailCode}
                                        onChange={(e) => setEmailCode(e.target.value)}
                                        required
                                        placeholder="Ingrese el código recibido en su correo"
                                        autocomplete="off" />
                                </div>
                                <div className='inputsAuth'>
                                    <label htmlFor="phoneOTP">OTP:</label>
                                    <input
                                        type="phoneOTP"
                                        id="phoneOTP"
                                        name="phoneOTP"
                                        value={phoneOTP}
                                        onChange={(e) => setPhoneOTP(e.target.value)}
                                        required
                                        placeholder="Ingrese el código recibido en su celular"
                                        autocomplete="off" />
                                </div>
                                <div className='inputsAuth'>
                                    <label htmlFor="contrasena">Contraseña:</label>
                                    <div className='deFlexInputs'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="contrasena"
                                            name="contrasena"
                                            value={contrasena}
                                            onChange={(e) => validate(e.target.value)}
                                            /* onFocus={(e) => () => { (contrasena == '') ? generatePassword : '' } } */
                                            required
                                            placeholder="Contraseña"
                                            autocomplete="new-password" />
                                        <button
                                            type="button"
                                            onClick={() => { generatePassword(); } }
                                        >
                                            <FontAwesomeIcon icon={faRefresh} />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                        <br />
                                        {errorMessage === '' ? null :
                                            <span style={{
                                                fontWeight: 'bold',
                                                color: (errorMessage === 'Es buena Contraseña') ? 'green' : 'red'
                                            }}>
                                                <FontAwesomeIcon icon={errorMessage === 'Es buena Contraseña' ? 'check' : 'times'} /> 
                                                {errorMessage}
                                            </span>}
                                    </div>
                                </div>
                                <div className='inputsAuth'>
                                    <label htmlFor="rol">Quiero:</label>
                                    <div className='deFlexInputs'>
                                        <select
                                            id="rol"
                                            name="rol"
                                            onChange={(e) => setRol(e.target.value)}
                                            required
                                        >
                                            <option value="tienda">Vender</option>
                                            <option value="usuario" selected>Comprar</option>
                                            {/* <option value="recolector">Hacer entregas</option> */}
                                        </select>
                                    </div>
                                </div>
                                <button type="submit" className="btnLogin">
                                    Completar registro
                                </button>
                            </form>
                            {/* <button type="button" className="btnLogin" onClick={() => authGeoLocation() }>Habilitar Ubicación</button>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={() => setModalIsOpen(false)}
                                contentLabel="Habilitar Ubicación"
                            >
                                <h2>Habilitar Ubicación</h2>
                                <p>Para continuar, necesitamos que habilites la ubicación en tu navegador.</p>
                                <button onClick={() => setModalIsOpen(false)}>Cerrar</button>
                            </Modal> */ }
                        </>
                    ) : (
                        <>
                            <h2>Registro</h2>
                            {
                                mensaje ? <p className={statusUserClass}>{mensaje}</p> : null
                            }
                            <form onSubmit={validarUsuario} className='formAuth'>
                                <div className='inputsAuth'>
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="Email (enviaremos un mensaje)"
                                        autocomplete="off" />
                                </div>
                                <div className='inputsAuth'>
                                    <label htmlFor="phone">Teléfono:</label>
                                    <input
                                        type="phone"
                                        id="phone"
                                        name="phone"
                                        value={phone}
                                        onChange={handleChangePhone}
                                        required
                                        minLength={10}
                                        placeholder="Número de teléfono (enviaremos un mensaje)"
                                        autocomplete="off" />
                                </div>
                                <div className='inputsAuth'>
                                    <label htmlFor="nombre">Nombre y apellidos:</label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        required
                                        placeholder="Nombre" />
                                </div>
                                <button type="submit" className='btnLogin'>
                                    Validar Correo y Teléfono
                                </button>
                            </form>
                        </>
                    )
                }
            </div>
        </>
    );
}
