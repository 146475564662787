import React, { useState, useEffect } from 'react';
import Login from '../Login/Login';
import { Link as Anchor, useNavigate } from 'react-router-dom';
import './Auth.css';
//import logo from './logo.png';

export default function Auth() {

    const [showLogin, setShowLogin] = useState(true);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');
    const navigate = useNavigate();

    useEffect(() => {

        getContacto();

    }, [contacto,rol]);

    const toggleComponent = () => {

        setShowLogin((prevShowLogin) => !prevShowLogin);

    };

    const getContacto = () => {

        try {

            if(localStorage.getItem('usuarios') !== null) {

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                //console.log(dataUser);

                //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
        
                setContacto(dataUser[0].idUsuario);

                setRol(dataUser[0].rol);

                //navigate('/');

            }
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            //toast.error(error.message);
    
        }

    };

    return (
        <div className='AuthContainer'>
            <>
                <Anchor to={`/`} >
                    <img src="/assets/images/logo_sivendo_192x192.webp" alt="sivendoonline.com" className='logoAtuh' />
                </Anchor>
                <Login />
            </>
            { /*(contacto === '') ? (
                <>
                    <Anchor to={`/`} >
                        <img src="/assets/images/logo_sivendo_192x192.webp" alt="sivendoonline.com" className='logoAtuh' />
                    </Anchor>
                    <Login />
                </>
            ) : (
                <>
                    <Anchor to={`/`} >
                        <img src="/assets/images/logo_sivendo_192x192.webp" alt="sivendoonline.com" className='logoAtuh' />
                    </Anchor>
                    <p>Ya iniciaste sesión como {rol}, click en el logo para ir al Inicio</p>
                </>
            ) */}
        </div>
    );
}
