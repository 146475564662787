import React, { useState, useEffect } from 'react';

const GeolocationExample = () => {
    
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (pos) => {
          setPosition({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        },
        (err) => {
          setError(err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );

      return () => navigator.geolocation.clearWatch(watchId);
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <div>
      <h1>Geolocation Example</h1>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <p>
          Latitude: {position.latitude} <br />
          Longitude: {position.longitude}
        </p>
      )}
    </div>
  );
};

export default GeolocationExample;
/*
Explanation:
State Management: We use useState to manage the position and error states.
Effect Hook: useEffect is used to set up the geolocation watcher when the component mounts and to clean it up when the component unmounts.
Geolocation API: navigator.geolocation.watchPosition is called to start watching the user’s position. If the position changes, the success callback updates the state with the new coordinates. If there’s an error, the error callback updates the state with the error message.
Cleanup: The watcher is cleared when the component unmounts to prevent memory leaks.
This example should help you get started with using geolocation in your ReactJS application.*/