import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin, faSave } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';

var thisLocation = JSON.parse(localStorage.getItem('ubicacion')) || null;

const dataContacto = JSON.parse(localStorage.getItem('contacto'));

const dataUser = JSON.parse(localStorage.getItem('usuarios')) || null;

//console.log('data user:');

//console.log(dataUser);

if(dataUser != null) {

  thisLocation.latitude = (parseFloat(dataUser.latitud)!==0) ? parseFloat(dataUser.latitud) : thisLocation.latitude;
    
  thisLocation.longitude = (parseFloat(dataUser.longitud)!==0) ? parseFloat(dataUser.longitud) : thisLocation.longitude;

  thisLocation.accuracy = (parseFloat(dataUser.accuracy)!==0) ? parseFloat(dataUser.accuracy) : thisLocation.accuracy;
    
  localStorage.setItem('ubicacion',JSON.stringify(thisLocation));

}

const GOOGLE_MAP_API_KEY = 'AIzaSyCC0U0iB71TD_DVBOB_DOrTs_ayPVEOyQo';

const INITIAL_LOCATION = {
  
  address: (dataContacto) ? dataContacto[0].direccion : '',
  
  position: {
  
    latitude: (thisLocation?.latitude) ? parseFloat(thisLocation.latitude) : 19.3909832,
  
    longitude: (thisLocation?.longitude) ? parseFloat(thisLocation.longitude) : -99.3084221,

    accuracy: (thisLocation?.accuracy) ? parseFloat(thisLocation.accuracy) : 0,
  
  }

};

//console.log(INITIAL_LOCATION);

const INITIAL_MAP_ZOOM_LEVEL = 18;

const ATLANTIC_OCEAN = {

  latitude: 19.3909832,
  
  longitude: -99.3084221

};

let ChangedAddress="";

class Map extends Component {

  data = {

    idContacto: 0,
  
    latitud: 0,
    
    longitud: 0,
    
    accuracy: 0,
    
    direccion: '',
    
    userid:0,
  
  };

  constructor(props) {

    super(props);
    
    this.state = {
      
      isGeocodingError: false,
      
      foundAddress: INITIAL_LOCATION.address
    
    };

    this.mapElement = React.createRef();
    
    this.searchInputElement = React.createRef();
    
    this.map = null;
    
    this.marker = null;
    
    this.geocoder = null;

    this.address = (dataContacto) ? dataContacto[0].direccion : '';

    //this.userid = dataUser[0].idUsuario;
    this.userid = (dataContacto) ? dataContacto[0].userid : 0;

    this.idContacto = (dataContacto) ? dataContacto[0].idContacto : 0;
  
  }

  componentDidMount() {

    const script = document.createElement('script');
    
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    
    script.async = true;

    script.onload = this.initMap;
      
    document.head.appendChild(script);

  }

  initMap = () => {

    this.map = new window.google.maps.Map(this.mapElement.current, {

      zoom: INITIAL_MAP_ZOOM_LEVEL,

      center: {

        lat: INITIAL_LOCATION.position.latitude,

        lng: INITIAL_LOCATION.position.longitude

      }

    });

    this.marker = new window.google.maps.Marker({

      map: this.map,

      position: {

        lat: INITIAL_LOCATION.position.latitude,

        lng: INITIAL_LOCATION.position.longitude

      }

    });

    this.geocoder = new window.google.maps.Geocoder();

    // Agrega un event listener al mapa para manejar el click
    this.map.addListener('click', this.handleMapClick);

    (this.address) ? this.geocodeAddress(this.address) : this.geocodeAddress('');

  }

  handleMapClick = (event) => {

    const clickedLocation = event.latLng;

    const latitude = clickedLocation.lat();
    
    const longitude = clickedLocation.lng();

    this.data.latitud = latitude;

    this.data.longitud = longitude;

    // Actualiza la posición del marcador
    this.marker.setPosition(clickedLocation);

    // Aquí puedes realizar cualquier acción adicional con las coordenadas obtenidas
  };

  geocodeAddress = (address) => {

    //console.log(`Geocoding address: ${address}`);

    //console.log(INITIAL_LOCATION.position.latitude+`, `+INITIAL_LOCATION.position.longitude);

    //this.map.setCenter([INITIAL_LOCATION.position.latitude,INITIAL_LOCATION.position.longitude]);
    
    //this.marker.setPosition([INITIAL_LOCATION.position.latitude,INITIAL_LOCATION.position.longitude]);

    this.geocoder.geocode({ 'address': address }, (results, status) => {
    
      if (status === window.google.maps.GeocoderStatus.OK) {

        //console.log(this.data);

        //console.log(results);
    
        this.setState({
    
          foundAddress: results[0].formatted_address,
    
          isGeocodingError: false
    
        });

        /*this.map.setCenter(results[0].geometry.location);
    
        this.marker.setPosition(results[0].geometry.location);

        */

        this.data.userid = this.userid;

        this.data.idContacto = this.idContacto;

        //console.log(`Latitude: ${results[0].geometry.location.lat()}`);

        //console.log(`Longitude: ${results[0].geometry.location.lng()}`);

        this.data.latitud = (results[0].geometry.location.lat() !==null) ? results[0].geometry.location.lat() : this.data.latitud;

        this.data.longitud = (results[0].geometry.location.lng() !==null) ? results[0].geometry.location.lng() : this.data.longitud;

        this.data.accuracy = 0;

        this.data.direccion = address;

        this.map.setCenter({
          
          lat: results[0].geometry.location.lat(),
    
          lng: results[0].geometry.location.lng()

        });
    
        this.marker.setPosition({
          
          lat: results[0].geometry.location.lat(),
          
          lng: results[0].geometry.location.lng()
        
        });

        //localStorage.setItem('usuarios',JSON.stringify(this.data));
    
        return;
    
      }

      this.setState({
    
        foundAddress: null,
    
        isGeocodingError: true
    
      });

      this.map.setCenter({
    
        lat: this.data.latitud,
    
        lng: this.data.longitud
    
      });

      this.marker.setPosition({
    
        lat: this.data.latitude,
    
        lng: this.data.longitude
    
      });
    
    });
  
  }

  handleFormSubmit = (submitEvent) => {
    
    submitEvent.preventDefault();

    this.ChangedAddress="";
    
    const address = this.searchInputElement.current.value;
    
    this.geocodeAddress(address);

    this.ChangedAddress="La ubicación ha sido actualizada. Si no corresponde, por favor use el mouse y haga click en el mapa para seleccionar la ubicación correcta.";
  
  }

  showSwal = () => {

    withReactContent(Swal).fire({

      title: 'Ubicación actualizada',
      
      text: 'La ubicación ha sido actualizada correctamente. Si no corresponde, por favor use el mouse y haga click en el mapa para seleccionar la ubicación correcta.',
      
      icon: 'success',
      
      confirmButtonText: 'Aceptar'

    })

  }

  handleUpdateLocation = () => {

    fetch(`${baseURL}/contactoUbicacionPut.php?idContacto=${this.data.idContacto}`, {

      method: 'PUT',

      headers: new Headers({

        'Content-Type': 'application/json',

        Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

      }),
      
      body: JSON.stringify(this.data),
    
    })
      .then(response => response.json())
      .then(data => {

        if (data.error)
      
          toast.error(data.error);
      
        else

          toast.success(data.mensaje);
    
      })
      .catch(error => {

          toast.error(error.message);

      });

  };

  render() {
    return (
      <>
        <div>
            <form>
              <fieldset>
                <input 
                  type="text" 
                  id="address" 
                  placeholder="Ingrese una dirección..." 
                  ref={this.searchInputElement} 
                  value={this.address}
                  required 
                />
                <button type="button" onClick={this.handleFormSubmit} className="btnLoading">
                  <FontAwesomeIcon icon={faLocationPin} />
                </button>
                <button type="button" onClick={this.handleUpdateLocation} className="btnPost">
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </fieldset>
            </form>
            {
              this.state.isGeocodingError 
              ? <p className="bg-danger">No se encontró la dirección.</p> 
              : (
                <> 
                  { /* <p className="bg-info">{this.state.foundAddress}</p> */ }
                  <p className="bg-info">{this.ChangedAddress}</p>
                </>
              )
            }
            <div className="map" ref={this.mapElement} style={{ height: '57vh', width: '100%' }}></div>
        </div>
      </>
    );
  }
}

export default Map;