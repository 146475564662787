import React from 'react'
import Nabvar from '../../Components/NavbarStore/Navbar'
import Banners from '../../Components/Banners/Banners'
//import Stores from '../../Components/Stores/Stores'
import Store from '../../Components/Store/Store'
import './Store.css'
import Footer from '../../Components/FooterStore/Footer'
export default function DemoStore() {
    return (
        <div className='section-bg-color'>
            <Nabvar />
            <div className='espaciobg'>

            </div>
            <section className='demo'>
                <Banners />
                <Store />
                <Footer />
            </section>
        </div>
    )
}