import React, { useEffect, useState } from 'react';
import './CardsCantidad.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faImage, faAddressBook, faTachometerAlt, faCode, faStore, faTicket, faMessage } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import baseURL from '../../url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CardsCantidad() {
    const [productos, setProductos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [banners, setBanners] = useState([]);
    const [categorias, setCategoras] = useState([]);
    const [contactos, setContactos] = useState([]);
    const [codigos, setCodigos] = useState([]);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');
    const [isfull, setIsFull] = useState('');
    const [loading, setLoading] = useState(false);

    const getContacto = () => {

        var dataContacto = 0;

        try {

            if(JSON.parse(localStorage.getItem('usuarios'))!== null) {

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                setContacto(dataUser[0].idUsuario);

                setRol(dataUser[0].rol);

                setIsFull(dataUser[0].servicio);

                dataContacto = (dataUser[0].rol !== 'admin') ? dataUser[0].idUsuario : 0;

            }

            return dataContacto;
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const cargarProductos = () => {

        const thisContacto = getContacto();

        fetch(`${baseURL}/productosGet.php?` + new URLSearchParams({

            contacto: thisContacto,

            showall: 'true'

        }), {
            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
            
        })
            .then(response => response.json())
            .then(data => {

                setProductos(data.productos || []);

                ////console.log(`Productos en cards: ${data.productos.length}`);

                //console.log(data.productos);

            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarPedidos = () => {

        const thisContacto = getContacto();
    
        fetch(`${baseURL}/pedidosGet.php?` + new URLSearchParams({

            contacto: thisContacto

        }), {
            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
        })
            .then(response => response.json())
            .then(data => {

                setPedidos(data.productos || []);

                ////console.log(`Pedidos en cardsCantidad: ${productos.length}`);

                //console.log(data.productos);


            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarUsuarios = () => {

        const thisContacto = getContacto();

        fetch(`${baseURL}/usuariosGet.php?`  + new URLSearchParams({
            userId: thisContacto
        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
        })
            .then(response => response.json())
            .then(data => {
                setUsuarios(data.usuarios || []);
            })
            .catch(error => console.error('Error al cargar usuarios:', error));
    };

    const cargarBanners = () => {

        const thisContacto = getContacto();

        fetch(`${baseURL}/bannersGet.php?` + new URLSearchParams({

            contacto: thisContacto

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {
                setBanners(data.banner || []);
                //console.log(data.banner)
            })
            .catch(error => console.error('Error al cargar banners:', error));
    };

    const cargarCategoria = () => {

        fetch(`${baseURL}/categoriasGet.php`, {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {
                setCategoras(data.categorias || []);
                //console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const cargarContacto = () => {

        const thisContacto = getContacto();

        fetch(`${baseURL}/contactoGet.php?`  + new URLSearchParams({

            userId: thisContacto
            
        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                setContactos(data.contacto || []);

                //console.log(data.contacto)

                if(data.contacto.length>0){
                    
                    //const thisContacto = JSON.parse(data.contacto[0].idContacto);
                    const thisContacto = JSON.stringify(data.contacto);

                    if(localStorage.getItem('contacto')!==null)

                        localStorage.removeItem('contacto');

                    localStorage.setItem('contacto', thisContacto);  

                }

            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const cargarCodigos = () => {

        const thisContacto = getContacto();

        fetch(`${baseURL}/codigosGet.php?`  + new URLSearchParams({

            userId: thisContacto

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {
                
                setCodigos(data.codigos || []);

            })
            .catch(error => console.error('Error al cargar códigos:', error));
    };

    useEffect(() => {

        if(loading!==true) {

            setTimeout(() => {

                cargarUsuarios();

                cargarProductos();
                
                cargarPedidos();
                
                cargarBanners();
                
                cargarCategoria();
                
                cargarContacto();
                
                cargarCodigos();    
    
            },1000);

        }

        setLoading(true);

    }, []);

    return (
        <div className='CardsCantidad'>
            <Anchor to={`/dashboard/pedidos`} className='cardCantidad' >
                <FontAwesomeIcon icon={faStore} className='icons' />
                <div className='deColumn'>

                    <h3>Pedidos</h3>
                    <h2>{pedidos.length}</h2>
                </div>
            </Anchor>
            {
                rol==='admin' ? (
                    <>
                        <Anchor to={`/dashboard/usuarios`} className='cardCantidad'>
                            <FontAwesomeIcon icon={faUser} className='icons' />
                            <div className='deColumn'>
                                <h3>Usuarios</h3>
                                <h2>{usuarios.length}</h2>
                            </div>
                        </Anchor>
                        <Anchor to={`/dashboard/productos`} className='cardCantidad' >
                            <FontAwesomeIcon icon={faBook} className='icons' />
                            <div className='deColumn'>

                                <h3>Productos</h3>
                                <h2>{productos.length}</h2>
                            </div>
                        </Anchor>
                        <Anchor to={`/dashboard/banners`} className='cardCantidad' >
                            <FontAwesomeIcon icon={faImage} className='icons' />
                            <div className='deColumn'>

                                <h3>Banners</h3>
                                <h2>{banners.length}</h2>
                            </div>
                        </Anchor>
                        <Anchor to={`/dashboard/categorias`} className='cardCantidad' >
                            <FontAwesomeIcon icon={faTachometerAlt} className='icons' />
                            <div className='deColumn'>

                                <h3>Categorias</h3>
                                <h2>{categorias.length}</h2>
                            </div>
                        </Anchor>
                        <Anchor to={`/dashboard/contacto`} className='cardCantidad' >
                            <FontAwesomeIcon icon={faAddressBook} className='icons' />
                            <div className='deColumn'>
                                <h3>Tiendas</h3>
                                <h2>{contactos.length}</h2>
                            </div>
                        </Anchor>
                        <Anchor to={`/dashboard/codigos`} className='cardCantidad' >
                            <FontAwesomeIcon icon={faTicket} className='icons' />
                            <div className='deColumn'>
                                <h3>Cupones</h3>
                                <h2>{codigos.length}</h2>
                            </div>
                        </Anchor>
                    </>

                ) : (
                    <>
                        <Anchor to={`/dashboard/productos`} className='cardCantidad' >
                            <FontAwesomeIcon icon={faBook} className='icons' />
                            <div className='deColumn'>
                                <h3>Productos</h3>
                                <h2>{productos.length}</h2>
                            </div>
                        </Anchor>
                        { 
                            isfull === 'Full' ? (
                                <>
                                    { /*<Anchor to={`/dashboard/notificaciones`} className='cardCantidad' >
                                        <FontAwesomeIcon icon={faMessage} className='icons' />
                                        <div className='deColumn'>
                                            <h3>Notificaciones</h3>
                                            <h2>{notificaciones.length}</h2>
                                        </div>
                                    </Anchor> */ }
                                    <Anchor to={`/dashboard/banners`} className='cardCantidad' >
                                        <FontAwesomeIcon icon={faImage} className='icons' />
                                        <div className='deColumn'>
                                            <h3>Banners</h3>
                                            <h2>{banners.length}</h2>
                                        </div>
                                    </Anchor>
                                    <Anchor to={`/dashboard/categorias`} className='cardCantidad' >
                                        <FontAwesomeIcon icon={faTachometerAlt} className='icons' />
                                        <div className='deColumn'>
                                            <h3>Categorias</h3>
                                            <h2>{categorias.length}</h2>
                                        </div>

                                    </Anchor>
                                    <Anchor to={`/dashboard/codigos`} className='cardCantidad' >
                                        <FontAwesomeIcon icon={faTicket} className='icons' />
                                        <div className='deColumn'>
                                            <h3>Cupones</h3>
                                            <h2>{codigos.length}</h2>
                                        </div>

                                    </Anchor>
                                    <Anchor to={`/dashboard/contacto`} className='cardCantidad' >
                                        <FontAwesomeIcon icon={faAddressBook} className='icons' />
                                        <div className='deColumn'>
                                            <h3>Tiendas</h3>
                                            <h2>{contactos.length}</h2>
                                        </div>
                                    </Anchor>
                                </>
                            ) : (
                                <Anchor to={`/dashboard/contacto`} className='cardCantidad' >
                                    <FontAwesomeIcon icon={faAddressBook} className='icons' />
                                    <div className='deColumn'>
                                        <h3>Tiendas</h3>
                                        <h2>{contactos.length}</h2>
                                    </div>
                                </Anchor>
                            )
                        }
                    </>
                )
            }

        </div>
    )
}
