import React, { useState, useEffect } from 'react';
import Nabvar from '../Components/Navbar/Navbar'
import Demo from '../Pages/Demo/Demo'
import baseURL from '../Components/url';
export default function IndexLayout() {

    const [location, setLocation] = useState({ latitude: null, longitude: null });

    useEffect(() => {

        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error getting location: ", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }

        ////console.log('MainLayout.js location: ', location);

    }, [location]);

    const validaConexion = () => {

        fetch(`${baseURL}/validaConexion.php`, {

            method: 'GET',

        })
            .then(response => response.json())

            .then(data => {

                if(data.error) {

                    //toast.error(data.error);

                    window.location.href = 'https://sites.google.com/sivendoonline.com/info/inicio';

                }

            })
            .catch(error => console.error('Error al hacer validar el usuario:', error));

    }

    validaConexion();

    return (
        <div className='section-bg-color'>
            <Nabvar />
            <div className='espaciobg'>

            </div>
            <Demo />
        </div>
    );
}