
import './App.css';
import { router, } from "./Pages/index";
import { RouterProvider } from "react-router-dom";
import React, { useEffect, useState } from 'react';
//import runOneSignal from './onesignal';
import OneSignal from 'react-onesignal';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {

  const [oneSignalId, setOneSignalId] = useState(null);

  useEffect(() => {

    const initializeOneSignal = async () => {

      await OneSignal.init({ 
        
        appId: '56efc65f-fa43-47da-bba7-ae3bddaf419c' 
      
      });

      //console.log('OneSignal initialized');

      //console.log(OneSignal);

      setTimeout(() => {

        //const isEnabled = OneSignal.isPushNotificationsEnabled();

        //if (isEnabled) {

          //const userId = OneSignal.User.PushSubscription.id;
          const userId = OneSignal.User.PushSubscription;

          setOneSignalId(userId);

          localStorage.setItem('pushNotData', JSON.stringify(userId));

          //console.log(`OneSignal User ID: ${userId}`);

        /*} else {

          console.log('Push notifications are not enabled.');

        }*/

        //OneSignal.showSlidedownPrompt();
        OneSignal.Slidedown.promptPush();

      }, 3000);

    };

    initializeOneSignal();

  }, []);

  const sendNotification = async () => {

    if (!oneSignalId) {

      //console.log('OneSignal ID not available');

      return;

    }

    const response = await fetch('https://onesignal.com/api/v1/notifications', {

      method: 'POST',

      headers: {

        'Content-Type': 'application/json',

        'Authorization': 'Basic YOUR_REST_API_KEY',

      },

      body: JSON.stringify({

        app_id: 'YOUR_APP_ID',

        include_player_ids: [oneSignalId],

        headings: { en: 'Notification Title' },

        contents: { en: 'Notification Message' },

      }),

    });

    const data = await response.json();

    //console.log('Notification response:', data);

  };

  const pushSubscriptionChangeListener = (event) => {

    if (event.current.token) {

      //console.log(`The push subscription has received a token!`);
      //this is a good place to call OneSignal.login and pass in your user ID
    
    }
  
  };
  
  OneSignal.User.PushSubscription.addEventListener("change", pushSubscriptionChangeListener);

  //OneSignal.User.addEmail("customer@company.com"); // This is an example add email
  //OneSignal.User.removeEmail("customer@company.com"); // This is an example remove email

  OneSignal.Debug.setLogLevel("error"); // 'trace', 'debug', 'info', 'warn', 'error', 'assert', 'silent'
  //'trace'
  //'debug'
  //'info'
  //'warn'
  //'error'
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LefAzoqAAAAAALAOQE6kEAgXb7XoFwxxHynriAP">
      <RouterProvider router={router} />
    </GoogleReCaptchaProvider>

  );
}

export default App;
