import React, { useState, useEffect } from 'react'
import './NavbarDashboard.css'
import { Link as Anchor, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faBook, faImage, faAddressBook, faTachometerAlt, faStore, faTicket, faMessage } from '@fortawesome/free-solid-svg-icons';
import Logout from '../Logout/Logout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Modal from 'react-modal';

export default function Navbar() {

    const location = useLocation();
    const [contacto, setContacto] = useState(0);
    const [rol, setRol] = useState('');
    const [isfull, setIsFull] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            setRol(dataUser[0].rol);

            setIsFull(dataUser[0].servicio);
    
        } catch (error) {

            setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    useEffect(() => {

        getContacto();

    }, []);

    return (
        <>
            <BrowserView>
                <div class="navbarDashboard" >
                    <Anchor to={`/`} className='logo'>
                        <img src="/assets/images/logo.png" alt="logo" />
                    </Anchor>
                    <div className='links'>
                        <Anchor to={`/dashboard`} className={location.pathname === '/dashboard' ? 'activeLink' : ''}><FontAwesomeIcon icon={faHome} /> Dashboard</Anchor>
                        <Anchor to={`/dashboard/notificaciones`} className={location.pathname === '/dashboard/notificaciones' ? 'activeLink' : ''}><FontAwesomeIcon icon={faMessage} /> Notificaciones</Anchor>
                        <Anchor to={`/dashboard/pedidos`} className={location.pathname === '/dashboard/pedidos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faStore} /> Pedidos</Anchor>
                        { 
                            rol === 'admin' ? (
                                <>
                                    {/* <Anchor 
                                    className={({ isActive }) =>
                                        isActive ? "activeLink" : ""
                                    }
                                    aria-current="page"
                                    to="/dashboard">
                                        <FontAwesomeIcon icon={faHome} /> Dashboard
                                    </Anchor> */}
                                    <Anchor to={`/dashboard/productos`} className={location.pathname === '/dashboard/productos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faBook} /> Productos</Anchor>
                                    <Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                                    <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink' : ''}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                                    <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                    <Anchor to={`/dashboard/usuarios`} className={location.pathname === '/dashboard/usuarios' ? 'activeLink' : ''}><FontAwesomeIcon icon={faUser} /> Usuarios</Anchor>
                                    <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTicket} /> Cupones</Anchor>
                                </>
                            ) : rol === 'tienda' ? (
                                <>
                                    <Anchor to={`/dashboard/productos`} className={location.pathname === '/dashboard/productos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faBook} /> Productos</Anchor>
                                    { 
                                        isfull === 'Full' ? (
                                            <>
                                                <Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                                                <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink' : ''}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                                                <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTicket} /> Cupones</Anchor>
                                                <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                            </>
                                        ) : (
                                            <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                        ) 
                                    }
                                </>
                            ) : rol === 'comprador' ? (
                                <>
                                    <Anchor to={`/dashboard/pedidos`} className={location.pathname === '/dashboard/pedidos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faStore} /> Pedidos</Anchor>
                                </>
                            ) : (
                                <div className='noRol'>
                                    <p>No tienes un rol asignado</p>
                                </div>
                            )

                        }
                    </div>
                    <Logout />
                </div>
            </BrowserView>
            <MobileView>
                    <div className='deFLexNavs'>
                        <Anchor to={`/`} className='logo-mobile-dashboard'>
                            <img src="/assets/images/logo.png" alt="logo" />
                        </Anchor>
                        <div className={`nav_toggle_sidenav  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    
                    <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNavMobile"
                    overlayClassName="overlay"
                    >
                        <div className='modalNavMobile-content'>
                            <div className="navbarDashboardMobile">
                                { 
                                    rol === 'admin' ? (
                                        <>
                                            <Anchor to={`/dashboard`} className={location.pathname === '/dashboard' ? 'activeLink' : ''}><FontAwesomeIcon icon={faHome} /> Dashboard</Anchor>
                                            <Anchor to={`/dashboard/notificaciones`} className={location.pathname === '/dashboard/notificaciones' ? 'activeLink' : ''}><FontAwesomeIcon icon={faMessage} /> Notificaciones</Anchor>
                                            <Anchor to={`/dashboard/pedidos`} className={location.pathname === '/dashboard/pedidos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faStore} /> Pedidos</Anchor>
                                            <Anchor to={`/dashboard/productos`} className={location.pathname === '/dashboard/productos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faBook} /> Productos</Anchor>
                                            <Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                                            <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink' : ''}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                                            <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                            <Anchor to={`/dashboard/usuarios`} className={location.pathname === '/dashboard/usuarios' ? 'activeLink' : ''}><FontAwesomeIcon icon={faUser} /> Usuarios</Anchor>
                                            <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTicket} /> Cupones</Anchor>
                                        </>
                                    ) : rol === 'tienda' ? (
                                        <>
                                            <Anchor to={`/dashboard`} className={location.pathname === '/dashboard' ? 'activeLink' : ''}><FontAwesomeIcon icon={faHome} /> Dashboard</Anchor>
                                            <Anchor to={`/dashboard/notificaciones`} className={location.pathname === '/dashboard/notificaciones' ? 'activeLink' : ''}><FontAwesomeIcon icon={faMessage} /> Notificaciones</Anchor>
                                            <Anchor to={`/dashboard/pedidos`} className={location.pathname === '/dashboard/pedidos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faStore} /> Pedidos</Anchor>
                                            <Anchor to={`/dashboard/productos`} className={location.pathname === '/dashboard/productos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faBook} /> Productos</Anchor>

                                            { 
                                                isfull === 'Full' ? (
                                                    <>
                                                        <Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                                                        <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink' : ''}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                                                        <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTicket} /> Cupones</Anchor>
                                                        <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                                    </>
                                                ) : (
                                                    <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                                ) 
                                            }

                                            { /*<Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                                            <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink' : ''}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                                            <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Tienda</Anchor>
                                            <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTicket} /> Cupones</Anchor> */ }
                                        </>
                                    ) : rol === 'comprador' ? (
                                        <>
                                            <Anchor to={`/dashboard`} className={location.pathname === '/dashboard' ? 'activeLink' : ''}><FontAwesomeIcon icon={faHome} /> Dashboard</Anchor>
                                            <Anchor to={`/dashboard/pedidos`} className={location.pathname === '/dashboard/pedidos`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faStore} /> Pedidos</Anchor>
                                        </>
                                    ) : (
                                        <div className='noRol'>
                                            <p>No tienes un rol asignado</p>
                                        </div>
                                    )

                                }
                                <Logout />
                            </div>
                        </div>
                    </Modal>
            </MobileView>
        </>

    );
}
