import React, { KeyboardEvent, useState, useEffect, useRef } from 'react';
import './NewContact.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import { useGeolocation } from '@uidotdev/usehooks';
import { usePlacesWidget } from "react-google-autocomplete";
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { geocodeByAddress } from 'react-google-places-autocomplete';
//import usePlacesAutocomplete, { getGeocode, getLatLng, } from "use-places-autocomplete";
//import useOnclickOutside from "react-cool-onclickoutside";

//export default function NewContact() {
export const NewContact = ({ values, onSubmit, onCancel }) => {

    const [currIndex, setCurrIndex] = useState(null);
    const [mensaje, setMensaje] = useState('');
    const [nombre, setNombre] = useState('');
    const [negocio, setNegocio] = useState('');
    const [telefono, setTelefono] = useState('');
    const [instagram, setInstagram] = useState('');
    const [email, setEmail] = useState('');
    const [direccion, setDireccion] = useState('');
    const [facebook, setFacebook] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [userId, setUserId] = useState('');
    const [latitud, setLatitud] = useState('');
    const [longitud, setLongitud] = useState('');
    const [accuracy, setAccuracy] = useState('');

    const [calle, setCalle] = useState('');
    const [colonia, setColonia] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [cpostal, setCpostal] = useState('');
    const [horario, setHorario] = useState(JSON.parse(`[
            {"dia": "Domingo", "abre": "00:00", "cierra": "00:00"}, 
            {"dia": "Lunes", "abre": "00:00", "cierra": "00:00"},
            {"dia": "Martes", "abre": "00:00", "cierra": "00:00"}, 
            {"dia": "Miércoles", "abre": "00:00", "cierra": "00:00"}, 
            {"dia": "Jueves", "abre": "00:00", "cierra": "00:00"}, 
            {"dia": "Viernes", "abre": "00:00", "cierra": "00:00"}, 
            {"dia": "Sábado", "abre": "00:00", "cierra": "00:00"}
        ]`));
    const [pagos, setPagos] = useState(JSON.parse(`[
            {"Transferencia": "0"},
            {"Efectivo": "0"},
            {"Tarjeta": "0"},
            {"Vale de Despensa": "0"},
            {"Criptomoneda": "0"},
            {"Otro": "0"}
        ]`));
    const [adomicilio, setAdomicilio] = useState('');
    const [tienda, setTienda] = useState('');
    const [apartado, setApartado] = useState('');
    const [hapartado, setHApartado] = useState('');
    
    useEffect(() => {

        //addHorario();

        getContacto();

        setTimeout(() => {

            addGeoData();

        }, 1000);

        /*const script = document.createElement('script');
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBRYNzdertTx0D7tE6wf_sMKa3SbuWEb3M&libraries=places";
        script.async = false;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }*/

    }, [userId]);

    const toggleModal = () => {
        setTelefono('');
        setInstagram('');
        setEmail('');
        setDireccion('');
        setFacebook('');
        setMensaje('');
        setCalle('');
        setColonia('');
        setCiudad('');
        setEstado('');
        setPais('');
        setCpostal('');
        setAdomicilio('');
        setTienda('');
        setApartado('');
        setHApartado('');
        setModalOpen(!modalOpen);
    };

    const crear = async () => {

        if(apartado === '1' && hapartado === ''){

            toast.error('Por favor, indique el tiempo máximo de apartado.');

            return;

        }

        const formData = new FormData();
        
        formData.append('nombre', nombre);

        formData.append('negocio', negocio);
        
        formData.append('telefono', telefono);
        
        formData.append('instagram', instagram);
        
        formData.append('email', email);
        
        formData.append('facebook', facebook);
        
        formData.append('userid', userId);

        formData.append('calle', calle);

        formData.append('colonia', colonia);

        formData.append('ciudad', ciudad);

        formData.append('estado', estado);

        formData.append('pais', pais);

        formData.append('cpostal', cpostal);

        formData.append('adomicilio', adomicilio);

        formData.append('horario', JSON.stringify(horario));

        formData.append('pagos', JSON.stringify(pagos));

        formData.append('tienda', tienda);

        formData.append('apartado', apartado);

        formData.append('hapartado', hapartado);

        setMensaje('Procesando...');

        try {

            const response = await fetch(`${baseURL}/contactoPost.php`, {
            
                method: 'POST',

                headers: new Headers({

                    /*'Content-Type': 'application/json',*/
    
                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),
            
                body: formData,
            
            });

            const data = await response.json();

            if (data.mensaje) {
            
                setMensaje('');
            
                toast.success(data.mensaje);

                localStorage.setItem('contacto', JSON.stringify(data.idContacto));
            
                toggleModal();

                //refreshContacto();
            
                //window.location.reload();
            
            } else if (data.error) {
            
                setMensaje('');
            
                toast.error(data.error);
            
            }
        
        } catch (error) {
        
            console.error('Error:', error);
        
            setMensaje('');
        
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        
        }
    
    };

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));
    
            setUserId(dataUser[0].idUsuario);
    
        } catch (error) {
    
            //console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const addGeoData = () => {

        const state = JSON.parse(localStorage.getItem('ubicacion'));

        if (state.loading === false) {

            setLatitud(state.latitude);
            
            setLongitud(state.longitude);
            
            setAccuracy(state.accuracy);

        }
            
    }

    const getLatLngfromAddress = async (address) => {
            
        try {

            //const address = document.getElementById('direccion').value;

            const results = await geocodeByAddress(address);

            //console.log('Get LatLongfromAddress is Success', results);

            return results;

            const latLng = await geocodeByLatLng({ lat: latitud, lng: longitud })(results[0]);

            //console.log('Get LatLongfromAddress is Success', latLng);

            //return latLng;

        } catch (error) {

            console.error('Get LatLongfromAddress with Error', error);

        }

    };

    const handleADomicilioChange = (e) => {

        setAdomicilio(e.target.value);

    };

    const handleTiendaChange = (e) => {

        setTienda(e.target.value);

    };

    const handleApartadoChange = (e) => {

        setApartado(e.target.value);

    };

    const AssignHorario = (dia,action,value) => {

        //console.log(`Dia es ${dia} y status es ${action} y value es ${value}`);

        const newHorario = horario.map(item => {

            if(item.dia === dia){

                item[action] = value;

            }

            return item;

        });

        setHorario(newHorario);

        //console.log('horario en AssignHorario');

        //console.log(horario);

    };

    const AssignPago = (pago,value) => {

        //console.log(`Pago es ${pago} y value es ${value}`);

        const newPagos = pagos.map(item => {

            if(item[pago]){

                item[pago] = value;

            }

            return item;

        });

        setPagos(newPagos);

        //console.log('pagos en AssignPago');

        //console.log(pagos);

    }

    const refreshContacto = () => {

        if(localStorage.getItem('usuarios')) {

            if(!localStorage.getItem('JWT_Token')) 

                return;

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            const thisUser = dataUser[0].idUsuario;

            fetch(`${baseURL}/refreshContacto.php?` + new URLSearchParams({

                contacto: thisUser
    
            }), {

                method: 'GET',
    
                headers: new Headers({
    
                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),
    
            })
                .then(response => response.json())

                .then(data => {

                    localStorage.setItem('usuarios', JSON.stringify(data));
                    //localStorage.setItem('usuarios', JSON.stringify(existingUsers));

                })
                .catch(error => console.error('Error al hacer refresh el usuario:', error));

        }

    }

    return (
        <>
            <form id="crearForm" onSubmit={onSubmit}>
                <Location />
                <div className="flex-container">
                    <div className='flex-item'>
                        <p><strong>Información de la tienda</strong></p>
                        <fieldset>
                            <legend>Nombre de la Tienda</legend>
                            <input
                                className='input'
                                type='text'
                                name='negocio'
                                value={negocio}
                                onChange={(e) => setNegocio(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Nombre del Contacto</legend>
                            <input
                                className='input'
                                type='text'
                                name='nombre'
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Telefono (WhatsApp)</legend>
                            <input
                                className='inputText'
                                type='text'
                                name='telefono'
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Instagram</legend>
                            <input
                                className='inputText'
                                type='url'
                                name='instagram'
                                value={instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>facebook</legend>
                            <input
                                className='inputText'
                                type='text'
                                name='facebook'
                                value={facebook}
                                onChange={(e) => setFacebook(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Email</legend>
                            <input
                                className='inputText'
                                type='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Servicio a domicilio</legend>
                            <select
                                id="adomicilio"
                                name="adomicilio"
                                value={adomicilio}
                                onChange={handleADomicilioChange}
                            >
                                <option value="0" selected>No</option>
                                <option value="1" selected>Sí</option>
                            </select>
                        </fieldset>
                    </div>
                    <div className='flex-item'>
                        <p><strong>Dirección</strong></p>
                        <fieldset>
                            <legend>Calle y número</legend>
                            <input
                                className='input'
                                type='text'
                                name='calle'
                                value={calle}
                                onChange={(e) => setCalle(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Colonia</legend>
                            <input
                                className='input'
                                type='text'
                                name='colonia'
                                value={colonia}
                                onChange={(e) => setColonia(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Ciudad</legend>
                            <input
                                className='input'
                                type='text'
                                name='ciudad'
                                value={ciudad}
                                onChange={(e) => setCiudad(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Estado</legend>
                            <input
                                className='input'
                                type='text'
                                name='estado'
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>País</legend>
                            <input
                                className='input'
                                type='text'
                                name='pais'
                                value={pais}
                                onChange={(e) => setPais(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Código Postal</legend>
                            <input
                                className='input'
                                type='text'
                                name='cpostal'
                                value={cpostal}
                                onChange={(e) => setCpostal(e.target.value)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Tienda F&iacute;sica</legend>
                            <select
                                id="tienda"
                                name="tienda"
                                value={tienda}
                                onChange={handleTiendaChange}
                            >
                                <option value="0" selected>No</option>
                                <option value="1" selected>Sí</option>
                            </select>
                        </fieldset>
                    </div>
                    <div className='flex-item'>
                        <fieldset>
                            <legend>Sistema de Apartado</legend>
                            <select
                                id="apartado"
                                name="apartado"
                                value={apartado}
                                onChange={handleApartadoChange}
                            >
                                <option value="0" selected>No</option>
                                <option value="1" selected>Sí</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <legend>Tiempo máximo de apartado (hrs)</legend>
                            <input
                                className='input'
                                type='number'
                                name='hapartado'
                                value={hapartado}
                                onChange={(e) => setHApartado(e.target.value)}
                                min={1}
                                max={720}
                            />
                        </fieldset>

                        <fieldset>
                            <legend>Formas de Pago</legend>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Pago</th>
                                        <th>Disponible</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { 
                                    pagos.map(item => (

                                        <tr key={Object.keys(item)}>
                                            <td>
                                                {Object.keys(item)}
                                            </td>
                                            <td>
                                                <select
                                                    id={Object.keys(item)}
                                                    name={Object.keys(item)}
                                                    value={Object.values(item)}
                                                    onChange={(e) => AssignPago(Object.keys(item),e.target.value)}
                                                >
                                                    <option value="0" selected>No</option>
                                                    <option value="1" selected>Sí</option>
                                                </select>
                                            </td>
                                        </tr>

                                    ))
                                }
                                </tbody>
                            </table>
                        </fieldset>
                        

                    </div>
                    <div className='flex-item'>
                        <fieldset>
                            <legend>Horario de Servicio</legend>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Dia</th>
                                        <th>Abre</th>
                                        <th>Cierra</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { 
                                    horario.map(item => (

                                        <tr key={item.dia}>
                                            <td>
                                                {item.dia}
                                            </td>
                                            <td>
                                                <input 
                                                    type="time" 
                                                    value={item.abre ?? ''}
                                                    onChange={(e) => AssignHorario(item.dia,'abre',e.target.value)}
                                                />

                                            </td>
                                            <td>
                                                <input 
                                                    type="time" 
                                                    value={item.cierra ?? ''}
                                                    onChange={(e) => AssignHorario(item.dia,'cierra',e.target.value)}
                                                />
                                            </td>
                                        </tr>

                                    ))
                                }
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
                <div
                    className="swal2-actions"
                    style={{ display: "flex", fontSize: "0.9em" }}
                >
                    {
                        mensaje ? (
                            <button type='button' className='btnLoading' disabled>
                                {mensaje}
                            </button>
                        ) : (
                            <button type='button' onClick={crear} className='btnPost'>
                                Agregar
                            </button>
                        )
                    }
                </div>
            </form>
        </>
    );

}

function Location() {

    const state = useGeolocation();

    const guardaUbicacion = (state) => {

        if(localStorage.getItem('ubicacion')!==null)

            localStorage.removeItem('ubicacion');

        localStorage.setItem('ubicacion',JSON.stringify(state));

    }

    //console.log(state);

    return (
        <>
            {
                (state.loading === false) ? (
                    <div state={guardaUbicacion(state)} />
                ) : (
                    <p className="text-info fw-bolder">Verificando permisos de ubicación.</p>
                )
            }
        </>
    );

}
