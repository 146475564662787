import React, { useState, useEffect, useCallback } from 'react';
import './btnNotification.css';
//import NotificationIcon from '../../images/pushnotificationbw.png';
import Modal from 'react-modal';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function BtnNotification() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { id } = useParams();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [mensaje, setMensaje] = useState('');

    const [contactos, setContactos] = useState([]);

    const [selectedContact, setSelectedContact] = useState(null);

    const [nameText, setNameText] = useState('');

    const [phoneText, setPhoneText] = useState('');

    const [thisLink, setThisLink] = useState('');

    //const [contacto, setContacto] = useState('');

    const [noteText, setNoteText] = useState('¡Hola! Estoy interesado en...');

    const [productoSeleccionado, setProductoSeleccionado] = useState([]);

    const [productocargado, setProductoCargado] = useState(false);

    useEffect(() => {

        if(productocargado===false) {

            const getProductoSeleccionado = () => {

                if(localStorage.getItem('productoSeleccionado')!==null) {

                    const productofromLS = JSON.parse(localStorage.getItem('productoSeleccionado')) || [];
        
                    try {
                        
                        setProductoSeleccionado(productofromLS);

                        //console.log('Producto seleccionado en variable productoSeleccionado');
        
                        //console.log(productoSeleccionado);

                    } catch (error) {

                        console.error('Error al cargar producto seleccionado:', error);

                    }
        
                    //console.log('Producto seleccionado en local storage');
        
                    //console.log(JSON.parse(localStorage.getItem('productoSeleccionado')));

                    //cargarContacto(productofromLS.contacto);

                    setNoteText(`${noteText} ${productofromLS.titulo} - ${productofromLS.descripcion} (${productofromLS.currency} ${productofromLS.precio}) \nVer ${window.location.hostname}/?id=${productofromLS.shorturl}`);

                    setThisLink(`${window.location.hostname}/?id=${productofromLS.shorturl}`);

                    setSelectedContact(productofromLS.contacto);
        
                }
        
            };

            setTimeout(() => {

                getProductoSeleccionado();

            }, 1000);

            setProductoCargado(true);

        }
    
    }, [productocargado, productoSeleccionado, noteText]);

    const cargarContacto = (thisContacto) => {

        //console.log(`Cargar contacto con id ${thisContacto}`)

        fetch(`${baseURL}/contactoGet.php?` + new URLSearchParams({

            userId: thisContacto

        }), {
            
            method: 'GET',
            
            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {

                //console.log('Contactos cargados:')

                //console.log(data.contacto);

                setContactos(data.contacto.reverse() || []);

            })
            .catch(error => console.error('Error al cargar contactos:', error));

    };

    const openModal = () => {

        if (noteText.trim() === '') {

            setNoteText(`¡Hola! Estoy interesado en... ${productoSeleccionado.titulo} - ${productoSeleccionado.descripcion} (${productoSeleccionado.precio}) \nVer $${window.location.hostname}/?id=${productoSeleccionado.shorturl}`);

        }

        if(thisLink.trim() === '')

            setThisLink(`${window.location.hostname}/?id=${productoSeleccionado.shorturl}`);

        //console.log('set link when open modal');

        //console.log(thisLink);

        setModalIsOpen(true);

    };

    const closeModal = () => {

        setModalIsOpen(false);

    };

    const handleNotificationMessage = useCallback(async (e) => {

        e.preventDefault();

        //console.log('Enviar mensaje de notificación');

        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        //const form = document.getElementById("frmsendNotification");
        const form = document.querySelector("#frmsendNotification");

        const formData = new FormData(form);

        if(localStorage.getItem('pushNotData')!==null) {

            const pushNotData = JSON.parse(localStorage.getItem('pushNotData'));

            const notifsrc = (pushNotData) ? pushNotData.id : '';

            var notiftoken = (localStorage.getItem('JWT_Token')) ? localStorage.getItem('JWT_Token') : pushNotData.token;

            if(notiftoken) {

                notiftoken = notiftoken.replace("https://fcm.googleapis.com/fcm/send/", "");

            }

            formData.append('notifsrc', notifsrc);

        }

        formData.append('notifdst', selectedContact);

        formData.append('producto', productoSeleccionado.idProducto);

        formData.append('recaptchaToken', token);

        const apartado = document.getElementById('apartado').checked ? 1 : 0;

        formData.append('apartado', apartado);

        if(thisLink.trim() === '')

            setThisLink(`${window.location.hostname}/?id=${productoSeleccionado.shorturl}`);

        formData.append('notiflink', thisLink);

        const resetForm = () => {

            form.reset();
        
        };
        
        setMensaje('');

        if (
            
            !formData.get('phone') ||
            
            !formData.get('notes')

        ) {
            
            toast.error('Por favor, agregue su número de teléfono.');
            
            return;
        
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/notificationsPost.php`, {
                
                method: 'POST',
                
                body: formData,
                
                headers: new Headers({

                    Authorization: 'Bearer ' + notiftoken,
    
                }),

            });

            const data = await response.json();

            if (data.mensaje) {

                setMensaje('');
                
                resetForm();
                
                toast.success(data.mensaje);

            } else if (data.error) {
                
                setMensaje('');
                
                toast.error(data.error);
                
                //console.log(data.error);

            }

        } catch (error) {
            
            console.error('Error:', error);
            
            setMensaje('');
            
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');

        }

        setNoteText('');

        closeModal();

    },[executeRecaptcha, selectedContact, productoSeleccionado, thisLink]);

    const sendNotification = useCallback(async (e) => {
        //const sendNotification = async () => {

        e.preventDefault();

        //console.log('Enviar mensaje de notificación');

        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        //const form = document.getElementById("frmsendNotification");
        const form = document.querySelector("#frmsendNotification");

        const formData = new FormData(form);

        if(localStorage.getItem('pushNotData')!==null) {

            const pushNotData = JSON.parse(localStorage.getItem('pushNotData'));

            const notifsrc = (pushNotData) ? pushNotData.id : '';

            var notiftoken = (localStorage.getItem('JWT_Token')) ? localStorage.getItem('JWT_Token') : pushNotData.token;

            if(notiftoken) {

                notiftoken = notiftoken.replace("https://fcm.googleapis.com/fcm/send/", "");

            }

            formData.append('notifsrc', notifsrc);

        }

        formData.append('notifdst', selectedContact);

        formData.append('producto', productoSeleccionado.idProducto);

        formData.append('recaptchaToken', token);

        const apartado = document.getElementById('apartado').checked ? 1 : 0;

        formData.append('apartado', apartado);

        if(thisLink.trim() === '')

            setThisLink(`${window.location.hostname}/?id=${productoSeleccionado.shorturl}`);

        formData.append('notiflink', thisLink);

        const resetForm = () => {

            form.reset();
        
        };
        
        setMensaje('');

        if (
            
            !formData.get('phone') ||
            
            !formData.get('notes')

        ) {
            
            toast.error('Por favor, agregue su número de teléfono.');
            
            return;
        
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/notificationsPost.php`, {
                
                method: 'POST',
                
                body: formData,
                
                headers: new Headers({

                    Authorization: 'Bearer ' + notiftoken,
    
                }),

            });

            const data = await response.json();

            if (data.mensaje) {

                setMensaje('');
                
                resetForm();
                
                toast.success(data.mensaje);

            } else if (data.error) {
                
                setMensaje('');
                
                toast.error(data.error);
                
                //console.log(data.error);

            }

        } catch (error) {
            
            console.error('Error:', error);
            
            setMensaje('');
            
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');

        }

        setNoteText('');

        closeModal();

        /*
        if (selectedContact || contactos?.length <= 1) {
            
            const phoneNumber = contactos?.length <= 1 ? contactos[0]?.telefono : selectedContact[0]?.telefono;

            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(noteText)}`;

            window.open(whatsappUrl, '_blank');

            setNoteText('');

            closeModal();

        }
        */

    },[executeRecaptcha, selectedContact, productoSeleccionado, thisLink]);

    return (
        <div id="btnNotification" className='containwnp'>
            <button className='notificon' onClick={openModal}>
                {/*<img src={NotificationIcon} alt="NotificationIcon" /> */}
                <FontAwesomeIcon icon={faBell} />
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-wnp"
                overlayClassName="overlay-wnp"
            >
                <div className='containModalwnp'>
                    <div className='headerwnp'>
                        <span>
                            Contactar al vendedor
                        </span>
                        <button onClick={closeModal} className='closeBtn'>
                            X
                        </button>
                    </div>
                    <div className='mensaje'>
                        <p>Hola, ¿en qué podemos ayudarte? 👋</p>
                    </div>
                    <form id="frmsendNotification" onSubmit={handleNotificationMessage}>
                        <div className="sendwnp">
                            <button type="submit">
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>
                        <div className="modal-send-form" style={{ overflow:'auto' }}>
                            <input 
                                type="text" 
                                id="name" 
                                name="name" 
                                placeholder="Nombre (opcional)"
                                value={nameText}
                                onChange={(e) => setNameText(e.target.value)}
                            />
                            <input 
                                type="text" 
                                id="phone" 
                                name="phone" 
                                placeholder="Teléfono (requerido)" 
                                value={phoneText}
                                onChange={(e) => setPhoneText(e.target.value)}
                                required />
                            { productoSeleccionado.apartado === 1 ?
                                (

                                    <div className="deFLexRadio">
                                        <input type="checkbox" id="apartado" name="apartado" defaultValue="1" defaultChecked />
                                        <label htmlFor="apartado">Preguntar por sistema de apartado</label>
                                    </div>

                                ) :
                                (
                                    <div className="deFLexRadio">
                                        <input type="checkbox" id="apartado" name="apartado" defaultValue="1" disabled />
                                        <label>Este vendedor no maneja sistema de apartado</label>
                                    </div>
                                )
                            }
                            {/*
                            <div className="deFLexRadio">
                                <label>Opciones de entrega</label>
                                <div>
                                    <input type="radio" id="delivery" name="deliveryOption" defaultValue="delivery" defaultChecked />
                                    <label htmlFor="delivery">Envío a domicilio</label>
                                </div>
                                <div>
                                    <input type="radio" id="pickup" name="deliveryOption" defaultValue="pickup" />
                                    <label htmlFor="pickup">Retirar personalmente</label>
                                </div>
                            </div>
                            <div className="deFLexRadio">
                                <label>Formas de pago</label>
                                <div>
                                    <input type="radio" id="efectivo" name="paymentMethod" defaultValue="efectivo" defaultChecked />
                                    <label htmlFor="efectivo">Efectivo</label>
                                </div>
                                <div>
                                    <input type="radio" id="transferencia" name="paymentMethod" defaultValue="transferencia" />
                                    <label htmlFor="transferencia">Transferencia</label>
                                </div>
                            </div>
                            <input type="text" id="codigo" name="codigo" placeholder="Codigo de descuento (opcional)" defaultValue />
                            */ }
                            <textarea 
                                id="notes" 
                                name="notes" 
                                placeholder="Agrega una nota (opcional)" 
                                value={noteText}
                                onChange={(e) => setNoteText(e.target.value)}
                                rows="5"
                            />
                        </div>
                    </form>
                    { /*<div className='sendwnp'>
                        <textarea
                            placeholder="Envíanos un mensaje"
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                            rows="5"
                        />
                        <button onClick={handleNotificationMessage}>
                            <FontAwesomeIcon icon={faBellConcierge} />
                        </button>
                    </div> */ }
                </div>
            </Modal>
        </div>
    );
}
