import React, { useState, useEffect } from 'react';
import Spiner from '../Components/Admin/Spiner/Spiner';
import { Outlet, useNavigate } from 'react-router-dom';
import Auth from '../Components/Auth/Auth';
import baseURL from '../Components/url';
import Nabvar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
export default function MainLayout() {
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getContacto();
        setLoading(false);
        /*const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/infoUserApi.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);

            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();*/
    }, [contacto,rol]);

    const getContacto = () => {

        try {

            if(localStorage.getItem('usuarios') !== null) {

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                //console.log(dataUser);

                //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);

                setUsuario(dataUser[0]);
        
                setContacto(dataUser[0].idUsuario);

                setRol(dataUser[0].rol);

                //navigate('/');

            }
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            //toast.error(error.message);
    
        }

    };

    return (
        <div>
            <div>

                <Nabvar />
                {loading ? (
                    <Spiner />
                ) : usuario.idUsuario ? (
                    <>
                        { /*<Outlet />
                        <Footer /> */ }
                        {
                        usuario.rol === 'admin' || usuario.rol === 'tienda' ? (
                            navigate("/dashboard")
                        ) : (
                            navigate("/")
                        )
                        }
                    </>
                ) : (
                    <>
                        <Auth />
                    </>
                )}
                <Footer />
            </div>

        </div>
    );
}
