import React, { useEffect, useState, useRef, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../url';
import './Banners.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useParams } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Banners() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { id } = useParams();
    
    const [images, setImages] = useState([]);
    
    const [loading, setLoading] = useState(true);
    
    const swiperRef = useRef(null);

    //console.log(`Store id ${id}`);

    useEffect(() => {
        cargarBanners(id);
    }, [executeRecaptcha]);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current?.update();
        }
    }, [images]);

    const cargarBanners = useCallback(async (contacto='') => {
        //const cargarBanners = () => {
        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');

        fetch(`${baseURL}/bannersGet.php?` + new URLSearchParams({

            recaptchaToken: token,

            store: contacto,

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {
                const bannerImages = data.banner.map(banner => banner.imagen);
                setImages(bannerImages);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error)

            });
    },[executeRecaptcha]);

    return (
        <div className='BannerContain'>

            {loading ? (
                <div className='loadingBanner'>

                </div>

            ) : (
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    loop={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                    navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    autoplay={{ delay: 3000 }}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => {
                        //console.log(swiper);
                        swiperRef.current = swiper;
                    }}
                    id='swiper_container'
                >
                    {images.map((image, index) => (
                        <SwiperSlide id='SwiperSlide-scroll' key={index}>
                            <img src={image} alt={`imagen-${index}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}


        </div>
    );
}
