import React, { useState } from 'react';
import './NewCategoria.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
//import { useNavigate } from 'react-router-dom';

export const NewCategoria = ({ values, onSubmit, onCancel }) => {
    //const navigate = useNavigate();
    const [mensaje, setMensaje] = useState('');
    const [categoria, setCategoria] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setCategoria('');
        setMensaje('');
        setModalOpen(!modalOpen);
    };


    const crear = async () => {

        const formData = new FormData();
        
        formData.append('categoria', categoria);

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/categoriasPost.php`, {

                method: 'POST',

                body: formData,

                headers: new Headers({

                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),
                
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                toggleModal();
                //window.location.reload();
                //navigate('/dashboard/categorias');
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <>
            <form id="crearForm" onSubmit={onSubmit}>
                <div className='flexGrap'>
                    <fieldset>
                        <legend>Categoría</legend>
                        <input
                            type='text'
                            name='categoria'
                            value={categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                        />
                    </fieldset>
                </div>
                <div
                    className="swal2-actions"
                    style={{ display: "flex", fontSize: "0.9em" }}
                >
                    {mensaje ? (
                        <button type='button' className='btnLoading' disabled>
                            {mensaje}
                        </button>
                    ) : (
                        <button type='button' onClick={crear} className='btnPost'>
                            Agregar
                        </button>
                    )}
                </div>
            </form>
        </>
    );
}
