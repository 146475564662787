import React, { useState, useEffect, useCallback } from 'react';
import './BtnWhatsapp.css';
import whatsappIcon from '../../images/wpp.png';
import Modal from 'react-modal';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default function BtnWhatsapp() {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [contactos, setContactos] = useState([]);

    const [selectedContact, setSelectedContact] = useState(null);

    //const [contacto, setContacto] = useState('');

    const [noteText, setNoteText] = useState('¡Hola! Estoy interesado en...');

    const [productoSeleccionado, setProductoSeleccionado] = useState([]);

    const [productocargado, setProductoCargado] = useState(false);

    /*
    //Function usando callback
    const getProductoSeleccionado = useCallback(() => {

        if(localStorage.getItem('productoSeleccionado')!==null) {

            setProductoSeleccionado(JSON.parse(localStorage.getItem('productoSeleccionado')));

            //console.log('Producto seleccionado');

            //console.log(productoSeleccionado);

        }

    }, [productoSeleccionado]);

    useEffect(() => {

        getProductoSeleccionado();
    
        getContacto();
    
        cargarContacto();
    
    }, [getProductoSeleccionado]);

    */

    useEffect(() => {

        if(productocargado===false) {

            const getProductoSeleccionado = () => {

                if(localStorage.getItem('productoSeleccionado')!==null) {

                    const productofromLS = JSON.parse(localStorage.getItem('productoSeleccionado')) || [];
        
                    setProductoSeleccionado(productofromLS);
        
                    //console.log('Producto seleccionado en local storage');
        
                    //console.log(JSON.parse(localStorage.getItem('productoSeleccionado')));

                    //console.log('Producto seleccionado en variable productoSeleccionado');
        
                    //console.log(productoSeleccionado);

                    cargarContacto(productofromLS.contacto);

                    setNoteText(`${noteText} ${productofromLS.titulo} - ${productofromLS.descripcion} (${productofromLS.currency} ${productofromLS.precio}) \nVer ${window.location.hostname}/?id=${productofromLS.shorturl}`);
        
                }
        
            };

            setTimeout(() => {

                getProductoSeleccionado();

            }, 1000);

            setProductoCargado(true);

        }
    
        //getContacto();
    
    }, [productocargado, productoSeleccionado, noteText]);

    const cargarContacto = (thisContacto) => {

        //console.log(`Cargar contacto con id ${thisContacto}`)

        fetch(`${baseURL}/contactoGet.php?` + new URLSearchParams({

            userId: thisContacto

        }), {
            method: 'GET',
            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
        })
            .then(response => response.json())
            .then(data => {

                //console.log('Contactos cargados:')

                //console.log(data.contacto);

                setContactos(data.contacto.reverse() || []);

            })
            .catch(error => console.error('Error al cargar contactos:', error));

    };

    /*const getContacto = () => {

        try {

            if(localStorage.getItem('cart') !== null) {

                const dataCart = JSON.parse(localStorage.getItem('cart'));

                //console.log(dataCart);

                //console.log(`Id Usuario es ${dataCart[0].contacto}`);

                var lastcontacto = 0;

                setContacto(dataCart[0].contacto);

                dataCart.forEach(item => {

                    //console.log(`Id Usuario es ${item.contacto} and last contacto es ${lastcontacto}`);

                    if(item.contacto !== lastcontacto)

                        setContacto(0);

                    lastcontacto = item.contacto;

                });

            }
    
        } catch (error) {
    
            console.error('Error:');
            console.error(error);
    
        }

    };
    */

    const openModal = () => {

        if (noteText.trim() === '') {

            setNoteText(`¡Hola! Estoy interesado en... ${productoSeleccionado.titulo} - ${productoSeleccionado.descripcion} (${productoSeleccionado.precio}) \nVer $${window.location.hostname}/?id=${productoSeleccionado.shorturl}`);

        }

        setModalIsOpen(true);

    };

    const closeModal = () => {

        setModalIsOpen(false);

    };

    const handleWhatsappMessage = () => {

        if (selectedContact || contactos?.length <= 1) {
            
            const phoneNumber = contactos?.length <= 1 ? contactos[0]?.telefono : selectedContact[0]?.telefono;

            /*
            //let noteMessage = '';

            if (noteText.trim() === '') {

                setNoteText(`\n¡Hola! Estoy interesado en.. ${productoSeleccionado.titulo} - ${productoSeleccionado.descripcion} (${productoSeleccionado.precio})`);

            }

            //const message = `${noteMessage}`;
            //const message = `${noteText}`;
            */

            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(noteText)}`;

            window.open(whatsappUrl, '_blank');

            setNoteText('');

            closeModal();

        }

    };

    return (
        <div id="btnWhatsApp" className='containWpp'>
            <button className='btnWhatsapp' onClick={openModal}>
                <img src={whatsappIcon} alt="whatsappIcon" />
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-wpp"
                overlayClassName="overlay-wpp"
            >
                <div className='containModalWpp'>


                    <div className='headerWpp'>

                        {contactos.length > 1 ? (
                            <span>
                                Selecciona un teléfono
                            </span>
                        ) : (
                            <span>
                                Envíanos un mensaje
                            </span>
                        )}
                        <button onClick={closeModal} className='closeBtn'>
                            X
                        </button>
                    </div>
                    <div className='mensaje'>
                        <p>Hola, ¿en qué podemos ayudarte? 👋</p>
                    </div>
                    <div className='btnsWpp'>
                        {contactos.length > 1 && (
                            <div className='btnsWpp'>
                                {contactos.map(item => (
                                    <button
                                        key={item.idContacto}
                                        className='btnWpp'
                                        style={{ backgroundColor: selectedContact && selectedContact.idContacto === item.idContacto ? 'green' : '' }}
                                        onClick={() => setSelectedContact(item)}
                                    >
                                        {item.telefono}
                                        <img src={whatsappIcon} alt="whatsappIcon" />
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='sendWpp'>
                        <textarea
                            placeholder="Envíanos un mensaje"
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                            rows="5"
                        />
                        <button onClick={handleWhatsappMessage}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
