import React, { useState, useEffect } from 'react';
import './InfoUser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import baseURL from '../url';
export default function InfoUser() {
    const navigate = useNavigate();
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);
    useEffect(() => {
        fetch(`${baseURL}/infoUserApi.php`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsuario(data);
                setLoading(false);
                //console.log(data)
            })
            .catch(error => {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            });
    }, []);

    const handleLogout = async () => {
        try {
            const response = await fetch(`${baseURL}/logout.php`, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();
                if (data.mensaje) {
                    //console.log(data.mensaje);
                    toast.success(data.mensaje);
                    setTimeout(() => {
                        navigate('/');
                        //window.location.reload();
                    }, 2000);
                } else if (data.error) {
                    //console.log(data.error);
                    toast.error(data.error);
                }
            } else {
                throw new Error('Error en la solicitud al servidor');
            }
        } catch (error) {
            console.error('Error:', error.message);
            toast.error(error.message);
        }
    };
    useEffect(() => {
        cargarBanners();
    }, []);

    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const bannerImages = data.banner.map(banner => banner.imagen);
                setImages(bannerImages);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error)

            });
    };
    return (
        <div className='userInfoContain'>
            <div className='bgUser' style={{
                backgroundImage: `url(${images[0]})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>

            </div>
            {loading ? (
                <div>cargando</div>
            ) : usuario?.idUsuario ? (
                <div className='userInfo'>
                    <div className='userName'>
                        <span>  {usuario.nombre.slice(0, 1)}</span>
                    </div>
                    <strong>   Hola,  {usuario?.nombre}</strong>
                    <h5>{usuario?.email}</h5>
                    <button onClick={handleLogout} >
                        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
                        Cerrar sesión
                    </button>
                </div>
            ) : (
                <div className='userInfo' >
                    <div className='userName'>
                        <span> J </span>
                    </div>
                    <strong>
                        Hola, Visitante
                    </strong>
                    <h5>SIVENDO Online</h5>
                    <button onClick={handleLogout} >
                        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
                        Cerrar sesión
                    </button>
                </div>
            )}
        </div>
    );
}
