import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faPlusCircle, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './ContactoData.css';
import 'jspdf-autotable';
import baseURL from '../../url';
import { NewContact } from '../NewContact/NewContact';
import Map from '../GeoUbicacion/geoMapUbicacion';
import withReactContent from 'sweetalert2-react-content';
import { json } from 'react-router-dom';

export default function ContactoData() {
    const [contactos, setContactos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoNegocio, setNuevoNegocio] = useState('');
    const [nuevoNombre, setNuevoNombre] = useState('');
    const [nuevoTelefono, setNuevoTelefono] = useState('');
    const [nuevoInstagram, setNuevoInstagram] = useState('');
    const [nuevoEmail, setNuevoEmail] = useState('');
    const [nuevaDireccion, setNuevaDireccion] = useState('');
    const [nuevofacebook, setNuevofacebook] = useState('');
    const [nuevoadomicilio, setNuevoadomicilio] = useState('');
    const [nuevoapartado, setNuevoapartado] = useState('');
    const [nuevotienda, setNuevotienda] = useState('');
    const [nuevohapartado, setNuevohapartado] = useState('');
    const [latitud, setLatitud] = useState('');
    const [longitud, setLongitud] = useState('');
    const [accuracy, setAccuracy] = useState('');
    const [contacto, setContacto] = useState({});
    const [selectedSection, setSelectedSection] = useState('texto');
    const [contactoId, setContactoId] = useState('');
    const [rol, setRol] = useState('');
    const [horario, setHorario] = useState({});
    const [pagos, setPagos] = useState({});
    const MySwal = withReactContent(Swal);

    useEffect(() => {

        getContacto();

        setTimeout(() => {

            cargarContacto(contacto,rol)

        },500);

        /*const script = document.createElement('script');
        
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBRYNzdertTx0D7tE6wf_sMKa3SbuWEb3M&libraries=places";
        
        script.async = false;
        
        script.defer = true;
        
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }*/

    }, [contactoId,rol]);

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            setRol(dataUser[0].rol);
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            //toast.error(error.message);
    
        }

    };

    const refreshContacto = () => {

        if(localStorage.getItem('usuarios')) {

            if(!localStorage.getItem('JWT_Token')) 

                return;

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            const thisUser = dataUser[0].idUsuario;

            fetch(`${baseURL}/refreshContacto.php?` + new URLSearchParams({

                contacto: thisUser
    
            }), {

                method: 'GET',
    
                headers: new Headers({
    
                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),
    
            })
                .then(response => response.json())

                .then(data => {

                    localStorage.setItem('usuarios', JSON.stringify(data));

                })
                .catch(error => console.error('Error al hacer refresh el usuario:', error));

        }

    }

    const cargarContacto = (userId,rol) => {

        fetch(`${baseURL}/contactoGet.php?`  + new URLSearchParams({
            userId: userId
        }), {
            method: 'GET',
            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),
        })
            .then(response => response.json())
            .then(data => {

                //console.log("Result is ");

                //console.log(data);

                if(data.contacto.length > 0){

                    setContactos(data.contacto || []);

                    //console.log(data.contacto);

                    //const thisContacto = JSON.parse(data.contacto[0].idContacto);
                    const thisContacto = JSON.stringify(data.contacto);

                    setContactoId(userId);

                    if(localStorage.getItem('contacto')!==null)

                        localStorage.removeItem('contacto');

                    localStorage.setItem('contacto', thisContacto); 

                }

            })
            .catch(
                error => console.error('Error al cargar contactos:', error)
            );

    };

    const eliminarContacto = (idContacto) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/contactoDelete.php?idContacto=${idContacto}`, {
                    method: 'DELETE',
                    headers: new Headers({

                        Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
        
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarContacto(contactoId,rol);
                    })
                    .catch(error => {
                        console.error('Error al eliminar contacto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const getJSON = (jsonval) => {

        let thisJSON = [];

        try {

            thisJSON = JSON.parse(jsonval);

            if (!Array.isArray(thisJSON)) {

                throw new Error('Este valor no es un array');

            }

        } catch (error) {

            console.error('Error al convertir el valor enviado a un array:', error);

            thisJSON = [];

        }

        return thisJSON;

    }

    const abrirModal = (item) => {
        setContacto(item);
        setNuevoNegocio(item.negocio);
        setNuevoNombre(item.nombre);
        setNuevoTelefono(item.telefono);
        setNuevoInstagram(item.instagram);
        setNuevoEmail(item.email);
        setNuevaDireccion(item.direccion);
        setNuevofacebook(item.facebook);
        setLatitud(item.latitud);
        setLongitud(item.longitud);
        setAccuracy(item.accuracy);
        setNuevoapartado(item.apartado);
        setNuevotienda(item.tienda);
        setNuevohapartado(item.hapartado);
        let thisHorario = getJSON(item.horario);
        setHorario(thisHorario);
        setNuevoadomicilio(item.adomicilio);
        let thisPagos = null;
        if(item.pagos)
            thisPagos = getJSON(item.pagos);
        else 
            thisPagos = JSON.parse(`[
                {"Transferencia": "0"},
                {"Efectivo": "0"},
                {"Tarjeta": "0"},
                {"Vale de Despensa": "0"},
                {"Criptomoneda": "0"},
                {"Otro": "0"}
            ]`);
        setPagos(thisPagos);
        setModalVisible(true);

    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleUpdateText = (idContacto) => {

        const payload = {
            negocio: nuevoNegocio !== '' ? nuevoNegocio : contacto.negocio,
            nombre: nuevoNombre !== '' ? nuevoNombre : contacto.nombre,
            telefono: nuevoTelefono !== '' ? nuevoTelefono : contacto.telefono,
            instagram: nuevoInstagram !== '' ? nuevoInstagram : contacto.instagram,
            email: nuevoEmail !== '' ? nuevoEmail : contacto.email,
            direccion: nuevaDireccion !== '' ? nuevaDireccion : contacto.direccion,
            facebook: nuevofacebook !== '' ? nuevofacebook : contacto.facebook,
            adomicilio: nuevoadomicilio !== '' ? nuevoadomicilio : contacto.adomicilio,
            horario: horario,
            pagos: pagos,
            tienda: nuevotienda !== '' ? nuevotienda : contacto.tienda,
            apartado: nuevoapartado !== '' ? nuevoapartado : contacto.apartado,
            hapartado: nuevohapartado !== '' ? nuevohapartado : contacto.hapartado
        };

        fetch(`${baseURL}/contactoPut.php?idContacto=${idContacto}`, {

            method: 'PUT',
            
            headers: new Headers({

                'Content-Type': 'application/json',

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

            body: JSON.stringify(payload),

        })
        .then(response => response.json())

        .then(data => {

            if (data.error) {

                Swal.fire(

                    'Error!',
                    
                    data.error,
                    
                    'error'
                
                );
            
            } else {
                
                Swal.fire(
                    
                    'Editado!',
                    
                    data.mensaje,
                    
                    'success'
                
                );
                
                cargarContacto(contactoId,rol);
                
                cerrarModal();

                //refreshContacto();
            
            }
        
        })
        
        .catch(error => {
            
            //console.log(error.message);
            
            toast.error(error.message);
        
        });
    
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    const nuevoContacto = values => {

        return new Promise((resolve, reject) => {
            MySwal.fire({
            title: "Agregar Contacto",
            html: (
                <>
                    <div className="modal">
                        <div className="modal-content"> 
                            <div className='deFlexBack'>
                                <h4>Agregar Contacto</h4>
                                <span className="close" onClick={() => Swal.close() } >&times;</span>
                            </div>
                            <NewContact
                                values={values}
                                onSubmit={() => {
                                    resolve();
                                    Swal.close();
                                }}
                                onCancel={() => Swal.close()}
                            />
                        </div>
                    </div>
                </>
            ),

            didClose: () => {

                getContacto();

                cargarContacto(contacto,rol);

                reject();

            },

                showConfirmButton: false

            });

        });

    };

    const showModal = () => {
        nuevoContacto({
            nombre:"",
            telefono: "",
            instagram: "",
            facebook:"",
            email:"",
            latitud:"",
            longitud:"",
            accuracy:"",
            horario: [],
            calle: "", 
            colonia: "", 
            ciudad: "", 
            estado: "", 
            pais: "",
            cpostal: "", 
            adomicilio: "", 
            tienda: "", 
            apartado: "", 
            hapartado: ""
        })
        .then(values => console.log(values))
        .catch(() => console.log("Modal closed"));
    };

    const newMap = values => {

        return new Promise((resolve, reject) => {
            MySwal.fire({
            title: "Ubicar mi tienda",
            html: (
                <>
                    <div className="modal">
                        <div className="modal-content"> 
                            <div className='deFlexBack'>
                                <h4>Ubicar mi tienda</h4>
                                <span className="close" onClick={() => Swal.close() } >&times;</span>
                            </div>
                            <Map
                                values={values}
                                onSubmit={() => {
                                    resolve();
                                    Swal.close();
                                }}
                                onCancel={() => Swal.close()}
                            />
                        </div>
                    </div>
                </>
            ),

            didClose: () => {

                getContacto();

                cargarContacto(contacto,rol);

                reject();

            },

                showConfirmButton: false

            });

        });

    };

    const showMap = () => {

        const dataUser = JSON.parse(localStorage.getItem('usuarios')) || null;

        //console.log('dataUser en ContactoData desde Local Storage')

        //console.log(dataUser);

        //console.log(`Latitude es ${dataUser[0].latitud} y Longitude es ${dataUser[0].longitud}`);

        newMap({

            latitud: (dataUser) ? parseFloat(dataUser[0].latitud) : 0,
            
            longitud: (dataUser) ? parseFloat(dataUser[0].longitud) : 0,
            
            accuracy: (dataUser) ? parseFloat(dataUser[0].accuracy) : 0
        
        })
        .then(values => console.log(values))
        .catch(() => console.log("Modal closed"));
    };

    const handleADomicilioChange = (e) => {

        setNuevoadomicilio(e.target.value);

    };

    const handleTiendaChange = (e) => {

        //console.log(`Valor de tienda es ${e.target.value}`);

        setNuevotienda(e.target.value);

    };

    const handleApartadoChange = (e) => {

        //console.log(`Valor de apartado es ${e.target.value}`);

        setNuevoapartado(e.target.value);

    };

    const UpdateHorario = (dia,action,value) => {

        //console.log(`Dia es ${dia} y status es ${action} y value es ${value}`);

        const newHorario = horario.map(item => {

            if(item.dia === dia){

                item[action] = value;

            }

            return item;

        });

        setHorario(newHorario);

        //console.log('horario en UpdateHorario');

        //console.log(horario);

    };

    const UpdatePago = (pago,value) => {

        //console.log(`Pago es ${pago} y value es ${value}`);

        const newPagos = pagos.map(item => {

            if(item[pago]){

                item[pago] = value;

            }

            return item;

        });

        setPagos(newPagos);

        //console.log('pagos en UpdatePago');

        //console.log(pagos);

    }

    return (
        <div>
            <ToastContainer />
            <div className='deFlex2'>
                <button className='btnSave' disabled= { (contactos.length > 0 && rol!=='admin' ) ? true : false } onClick={showModal}><FontAwesomeIcon icon={faPlusCircle} /> Agregar</button>
                <button className='btnSave' onClick={showMap}><FontAwesomeIcon icon={faLocationPin} />Ubicar mi tienda</button>
            </div>

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Modificar Datos
                                </button>

                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                        <div className="flex-container">
                            <div className='flex-item'>
                                <p><strong>Información de mi tienda</strong></p>
                                <fieldset>
                                    <legend>Nombre de la tienda</legend>
                                    <input
                                        type="text"
                                        value={nuevoNegocio !== '' ? nuevoNegocio : contacto.negocio}
                                        onChange={(e) => setNuevoNegocio(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Nombre de Contacto</legend>
                                    <input
                                        type="text"
                                        value={nuevoNombre !== '' ? nuevoNombre : contacto.nombre}
                                        onChange={(e) => setNuevoNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Telefono</legend>
                                    <input
                                        type="number"
                                        value={nuevoTelefono !== '' ? nuevoTelefono : contacto.telefono}
                                        onChange={(e) => setNuevoTelefono(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Instagram</legend>
                                    <input
                                        type="url"
                                        value={nuevoInstagram !== '' ? nuevoInstagram : contacto.instagram}
                                        onChange={(e) => setNuevoInstagram(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>email</legend>
                                    <input
                                        type="email"
                                        value={nuevoEmail !== '' ? nuevoEmail : contacto.email}
                                        onChange={(e) => setNuevoEmail(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>Direccion</legend>
                                    <input
                                        type="text"
                                        value={nuevaDireccion !== '' ? nuevaDireccion : contacto.direccion}
                                        onChange={(e) => setNuevaDireccion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>Facebook</legend>
                                    <input
                                        type="text"
                                        value={nuevofacebook !== '' ? nuevofacebook : contacto.facebook}
                                        onChange={(e) => setNuevofacebook(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Tienda F&iacute;sica</legend>
                                    <select
                                        value={nuevotienda}
                                        onChange={handleTiendaChange}
                                    >
                                        <option value="" disabled selected>Selecciona una opción</option>
                                        <option value="0">No</option>
                                        <option value="1">Sí</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Servicio a domicilio</legend>
                                    <select
                                        value={nuevoadomicilio}
                                        onChange={handleADomicilioChange}
                                    >
                                        <option value="" disabled selected>Selecciona una opción</option>
                                        <option value="0" selected>No</option>
                                        <option value="1" selected>Sí</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Sistema de Apartado</legend>
                                    <select
                                        value={nuevoapartado}
                                        onChange={handleApartadoChange}
                                    >
                                        <option value="" disabled selected>Selecciona una opción</option>
                                        <option value="0" selected>No</option>
                                        <option value="1" selected>Sí</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Horas</legend>
                                    <input
                                        className='input'
                                        type='number'
                                        value={ nuevohapartado ?? contacto.hapartado}
                                        onChange={(e) => setNuevohapartado(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Formas de Pago</legend>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Pago</th>
                                                <th>Disponible</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { 
                                            pagos.map(item => (

                                                <tr key={Object.keys(item)}>
                                                    <td>
                                                        {Object.keys(item)}
                                                    </td>
                                                    <td>
                                                        <select
                                                            id={Object.keys(item)}
                                                            name={Object.keys(item)}
                                                            value={Object.values(item)}
                                                            onChange={(e) => UpdatePago(Object.keys(item),e.target.value)}
                                                        >
                                                            <option value="0" selected>No</option>
                                                            <option value="1" selected>Sí</option>
                                                        </select>
                                                    </td>
                                                </tr>

                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </fieldset>

                                <fieldset>
                                    <legend>Horario de Servicio</legend>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Dia</th>
                                                <th>Abre</th>
                                                <th>Cierra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { 
                                            horario.map(item => (

                                                <tr key={item.dia}>
                                                    <td>
                                                        {item.dia}
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="time" 
                                                            value={item.abre ?? ''}
                                                            onChange={(e) => UpdateHorario(item.dia,'abre',e.target.value)}
                                                        />

                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="time" 
                                                            value={item.cierra ?? ''}
                                                            onChange={(e) => UpdateHorario(item.dia,'cierra',e.target.value)}
                                                        />
                                                    </td>
                                                </tr>

                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </fieldset>
                            </div>
                        </div>
                            <button className='btnPost' onClick={() => handleUpdateText(contacto.idContacto)} >Guardar </button>
                        </div>
                    </div>
                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id Tienda</th>
                            <th>Nombre de la tienda</th>
                            <th>Nombre del Contacto</th>
                            <th>Telefono</th>
                            <th>Instagram</th>
                            <th>Facebook</th>
                            <th>Email</th>
                            <th>Direccion</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contactos.map(item => (
                            <tr key={item.idContacto}>
                                <td>{item.idContacto}</td>
                                <td>{item.nombre}</td>
                                <td>{item.negocio}</td>
                                <td>{item.telefono}</td>
                                <td>{item.instagram}</td>
                                <td>{item.facebook}</td>
                                <td>{item.email}</td>
                                <td>{item.direccion}</td>
                                <td>
                                    <button className='eliminar' onClick={() => eliminarContacto(item.idContacto)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};
