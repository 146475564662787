import React, { useState } from 'react';
import LoginForm from '../LoginForm/LoginForm';
//import RegisterForm from '../RegisterForm/RegisterForm';
import RegisterForm from '../RegisterForm/newRegisterForm';
//import logo from '../../images/logo.png';
//import logo from '../../images/logo.png';
import { Link as Anchor } from 'react-router-dom';
export default function Auth() {

    const [showLogin, setShowLogin] = useState(true);

    const toggleComponent = () => {

        setShowLogin((prevShowLogin) => !prevShowLogin);

    };

    return (
        <div className='AuthContainer'>
            <Anchor to={`/`} >
                <img src="/assets/images/logo_sivendo_192x192.webp" alt="sivendoonline.com" className='logoAtuh' />
            </Anchor>
            {showLogin ? <LoginForm /> : <RegisterForm />}
            { /* <LoginForm /> */ }
            <button onClick={toggleComponent} className='sinCuenta'>
                {showLogin ? (
                    <>
                        ¿No tienes una cuenta? <span>Registrarse</span>
                        <br />Disponible solo en México.
                        {/*¿No tienes una cuenta? 
                        <span>Escríbenos a ventas@sivendoonline.com</span>
                        <br />Disponible solo en México. */ }
                    </>
                ) : (
                    <>
                        ¿Ya tienes una cuenta? <span>Iniciar sesión</span>
                    </>
                )}
            </button>
        </div>
    );
}
