import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlusCircle, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import { NewCodigo } from '../NewCodigo/NewCodigo';
import './CodigosData.css';

export default function CodigosData() {
    const [codigos, setCodigos] = useState([]);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');

    const MySwal = withReactContent(Swal);

    useEffect(() => {

        getContacto();

        cargarCodigos(contacto,rol);

    }, [contacto,rol]);

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            setRol(dataUser[0].rol);
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const cargarCodigos = (contacto,rol) => {

        fetch(`${baseURL}/codigosGet.php?` + new URLSearchParams({

            userId: (rol !== 'admin') ? contacto : 0

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })
            .then(response => response.json())
            .then(data => {
                setCodigos(data.codigos || []);
            })
            .catch(error => console.error('Error al cargar códigos:', error));
    };

    const eliminarCodigo = (idCodigo) => {

        Swal.fire({
            
            title: '¿Estás seguro?',
            
            text: '¡No podrás revertir esto!',
            
            icon: 'warning',
            
            showCancelButton: true,
            
            confirmButtonColor: '#3085d6',
            
            cancelButtonColor: '#d33',
            
            confirmButtonText: 'Sí, inactivar',
            
            cancelButtonText: 'Cancelar',
        
        }).then((result) => {
            
            if (result.isConfirmed) {
            
                fetch(`${baseURL}/codigosDelete.php?idCodigo=${idCodigo}`, {
            
                    method: 'DELETE',

                    headers: new Headers({

                        Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
        
                    }),
            
                })
            
                .then(response => response.json())
            
                .then(data => {
            
                    Swal.fire(
            
                        'Inactivado!',
            
                        data.mensaje,
            
                        'success'
            
                    );
            
                    cargarCodigos(contacto,rol);
            
                })
            
                .catch(error => {
            
                    console.error('Error al inactivar el código:', error);
            
                    toast.error(error);
            
                });
            
            }
        
        });
    
    };

    const nuevoCodigo = values => {

        return new Promise((resolve, reject) => {
            MySwal.fire({
            title: "Nuevo Código",
            html: (
                <>
                    <div className="modal">
                        <div className="modal-content"> 
                            <div className='deFlexBack'>
                                <h4>Agregar Código</h4>
                                <span className="close" onClick={() => Swal.close() } >&times;</span>
                            </div>
                            <NewCodigo
                                values={values}
                                onSubmit={() => {
                                    resolve();
                                    Swal.close();
                                }}
                                onCancel={() => Swal.close()}
                            />
                        </div>
                    </div>
                </>
            ),
            didClose: () => {
                getContacto();
                cargarCodigos(contacto,rol);
                reject()
            },
                showConfirmButton: false
            });
        });
    };

    const showModal = () => {
        nuevoCodigo({
            codigo:"",
            descuento: "",
            vence: ""
        })
        .then(values => console.log(values))
        .catch(() => console.log("Modal closed"));
    };

    return (
        <div>
            <ToastContainer />
            <button className='btnSave' onClick={showModal}><FontAwesomeIcon icon={faPlusCircle} /> Agregar</button>
            { /* <NewCodigo /> */ }


            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descuento</th>
                            <th>Total</th>
                            <th>En Uso</th>
                            <th>Inicia</th>
                            <th>Vence (dias)</th>
                            <th>Vence (fecha)</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {codigos.map(item => (
                            <tr key={item.idCodigo} className={(item.codstatus==='1') ? 'bg-info' : 'bg-danger' }>
                                <td>{item.codigo}</td>
                                <td style={{ color: 'green' }}>${item.descuento?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                <td>{item.maxuso}</td>
                                <td>{item.usados}</td>
                                <td>{item.inicia}</td>
                                <td>{item.vence}</td>
                                <td>{item.vencefecha}</td>
                                <td>
                                    {
                                        item.codstatus==='1' ?
                                        (
                                            <button className='eliminar' onClick={() => eliminarCodigo(item.idCodigo)}>
                                                <FontAwesomeIcon icon={faArrowAltCircleDown} />
                                            </button>
                                        )
                                        :
                                        (
                                            <p className="bg-danger">En desuso</p>
                                        )
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
