import React, { useState, useEffect } from 'react';
import Spiner from '../Components/Admin/Spiner/Spiner';
import { Outlet } from 'react-router-dom';
import Auth from '../Components/Admin/Auth/Auth';
import baseURL from '../Components/url';
import logo from '../images/logo.webp';

export default function MainLayout() {
    
    const [usuario, setUsuario] = useState({});
    
    const [loading, setLoading] = useState(true);
    
    const [contacto, setContacto] = useState('');
    
    const [rol, setRol] = useState('');

    useEffect(() => {
    
        getContacto();
    
        setLoading(false);
    
        /*const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);

            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();*/

    }, [contacto,rol]);

    const getContacto = () => {

        try {

            if(localStorage.getItem('usuarios') !== null) {

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                //console.log(dataUser);

                //console.log(`MainLayout Id Usuario es ${dataUser[0].idUsuario}`);

                setUsuario(dataUser[0]);
        
                setContacto(dataUser[0].idUsuario);

                setRol(dataUser[0].rol);

                //navigate('/');

            }
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            //toast.error(error.message);
    
        }

    };

    return (
        <div>
            <div>
                {loading ? (
                    <Spiner />
                ) : usuario.idUsuario ? (
                    <>
                        {
                        usuario.rol === 'admin' || usuario.rol === 'tienda' ? (
                            <Outlet />
                        ) : (
                            <Auth />
                        )
                        }
                    </>
                ) : (
                    <Auth />
                )}
            </div>
        </div>
    );
}
