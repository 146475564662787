import React, { useEffect, useState, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import { Link as Anchor } from 'react-router-dom';
//import logo from './assets/images/logo.png';
import baseURL from '../url';
import 'swiper/swiper-bundle.css';
import Profile from '../Profile/Profile';
import Stores from '../Stores/Stores';
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSignIn, faSignOut, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ButonInstallAppNav from '../Admin/ButonInstallAppNav/ButonInstallAppNav';
import Logout from '../Admin/Logout/mainLogout';
import Notifications from '../Admin/Notifications/Notifications';
import { useParams } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useGeolocation } from '@uidotdev/usehooks';

export default function Navbar() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { id } = useParams();

    const [isOpen, setIsOpen] = useState(false);
    
    const [images, setImages] = useState([]);
    
    const [loading, setLoading] = useState(true);
    
    const [usuario, setUsuario] = useState(null);

    useEffect(() => {

        getContacto();

        cargarBanners(id);
        
    }, [executeRecaptcha,id]);

    //console.log(`Store id ${id}`);

    const cargarBanners = useCallback(async (contacto) => {

        if (!executeRecaptcha) {

            //console.log("Execute recaptcha not yet available");

            return;

        }

        const token = await executeRecaptcha('login');
        //const cargarBanners = () => {

        fetch(`${baseURL}/bannersGet.php?` + new URLSearchParams({

            store: contacto,

            recaptchaToken: token,

        }), {

            method: 'GET',

        })
            .then(response => response.json())
            .then(data => {

                const bannerImages = data.banner.map(banner => banner.imagen);
                
                setImages(bannerImages);
                
                setLoading(false);
            
            })
            .catch(error => {
            
                console.error('Error al cargar productos:', error)

            });
    },[executeRecaptcha]);

    const getContacto = () => {

        try {

            if(localStorage.getItem('usuarios') !== null){

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                //console.log(dataUser);

                //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
        
                setUsuario(dataUser[0].nombre);

            }
    
        } catch (error) {
    
            console.error('Error:', error.message);
    
        }

    };

    return (
        <header>
            <nav>
                <div className='deFLexNavs'>
                    <BrowserView>
                        <Anchor to={`/`} className='logo'>
                            <img src="/assets/images/logo.webp" alt="logo" />
                        </Anchor>
                    </BrowserView>
                    <MobileView>
                        <Anchor to={`/`} className='logo-mobile'>
                            <img src="/assets/images/logo.png" alt="logo" />
                        </Anchor>
                    </MobileView>
                    <ButonInstallAppNav />
                    <Anchor to={`/`} className='btn-sesion' >
                        <FontAwesomeIcon icon={faHome} className='icon'/> 
                    </Anchor>
                    <Anchor to={`/auth/user`} className='btn-sesion' >
                        { (usuario ) 
                            ?   <>
                                <FontAwesomeIcon icon={faUserCircle} className='icon'/> 
                                { usuario.charAt(0) }
                                </>
                            : <FontAwesomeIcon icon={faUser} className='icon' />
                        }
                        { /*
                            <FontAwesomeIcon icon={faUser} className='icon' />
                                { (usuario) ? usuario.charAt(0) : 'V'}
                        */ 
                        }
                        { /* <FontAwesomeIcon icon={faUser} /> {usuario ? usuario : ''} */ }
                    </Anchor>
                    <Notifications />
                    {
                        (usuario) 
                        ? <Logout /> 
                        : ''
                    }
                    <div className={`nav_toggle  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        {loading ? (
                            <div className='loadingBanner'>

                            </div>

                        ) : (

                            <div className='fondo'>
                                <img src={images[0]} alt={`imagen`} />
                                <Profile />
                            </div>

                        )}

                    </div>
                </Modal>
            </nav>
        </header>
    );
}
