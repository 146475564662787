import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import '../Register/Register.css';

export const ModalForm = ({ values, onSubmit, onCancel }) => {
    const [nombre, setNombre] = useState('');
    const [contacto, setContacto] = useState('');
    const [email, setEmail] = useState('');
    const [rol, setRol] = useState(''); // Estado para el rol
    const [contrasena, setContrasena] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [mensaje2, setMensaje2] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [formularioEnviado, setFormularioEnviado] = useState(false);

    useEffect(() => {
        getContacto();
    }, []);

    const agregarUsuario = async (e) => {
        e.preventDefault();
        setMensaje2('Procesando...')
        try {
            const formData = new FormData();
            formData.append('nombre', nombre);
            formData.append('email', email);
            formData.append('contacto', contacto);
            formData.append('rol', rol);
            formData.append('contrasena', contrasena);

            const response = await fetch(`${baseURL}/registroPost.php`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                if (data.mensaje) {
                    //setDisabled(!isDisabled);
                    setMensaje(data.mensaje);
                    toast.success(data.mensaje);
                    setMensaje2('')
                    setFormularioEnviado(false);

                } else if (data.error) {
                    setError(data.error);
                    toast.error(data.error);
                    setMensaje2('')

                }
            } else {
                throw new Error('Error en la solicitud al servidor');
            }
        } catch (error) {
            console.error('Error:', error.message);
            toast.error(error.message);
            setMensaje2('')
        }
    };

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);
    
        } catch (error) {

            setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const getRoles = () => {

        //console.log('Pending');
    }

    return (
        <>
            <form onSubmit={onSubmit} id='crearForm'>
                <div className='flexGrap'>
                    <fieldset>
                        <legend>Nombre</legend>
                        <input
                            type="text"
                            id="nombre"
                            name="nombre"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            required
                            placeholder="Nombre"
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Email</legend>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email"
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Rol</legend>
                        <select
                            id="rol"
                            name="rol"
                            onChange={(e) => setRol(e.target.value)}
                            required
                        >
                            <option value="">Seleccione un rol</option>
                            <option value="tienda">Vendedor</option>
                            <option value="usuario">Usuario</option>
                            <option value="recolector">Recolector</option>
                            <option value="admin">Admin</option>
                        </select>
                    </fieldset>
                    <fieldset>
                        <legend>Contraseña</legend>
                        <div className='deFlexPass'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="contrasena"
                                name="contrasena"
                                value={contrasena}
                                onChange={(e) => setContrasena(e.target.value)}
                                required
                                placeholder="Contraseña"
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        </div>
                    </fieldset>
                </div>
                <div
                    className="swal2-actions"
                    style={{ display: "flex", fontSize: "0.9em" }}
                >
                    {mensaje2 ? (
                        <button type="button" className='btnLoading' disabled={isDisabled}>
                            {mensaje2}
                        </button>
                    ) : (
                        <>
                            <button type="submit" onClick={agregarUsuario} className='swal2-confirm swal2-styled'>
                                Agregar
                            </button>
                            <button type="button" onClick={onCancel} className="swal2-cancel swal2-styled">
                                Cancel
                            </button>
                        </>
                    )}
                </div>
            </form>
        </>
    )
};
