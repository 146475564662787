import React, { useEffect, useState } from 'react'
import './Main.css'
import Header from '../Header/Header'
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash'
import ProductosMain from '../../Components/Admin/ProductosMain/ProductosMain'
import UsuariosMain from '../../Components/Admin/UsuariosMain/UsuariosMain'
import CardsCantidad from '../../Components/Admin/CardsCantidad/CardsCantidad'
import InfoUserMain from '../../Components/Admin/InfoUserMain/InfoUserMain'
import baseURL from '../../Components/url'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Main() {

    const [contacto, setContacto] = useState(0);

    const [rol, setRol] = useState('');

    useEffect(() => {

        getContacto();

    }, []);

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            setRol(dataUser[0].rol);
    
        } catch (error) {
    
            //console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const refreshContacto = () => {

        if(localStorage.getItem('usuarios')) {

            if(!localStorage.getItem('JWT_Token')) 

                return;

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            const thisUser = dataUser[0].idUsuario;

            fetch(`${baseURL}/refreshContacto.php?` + new URLSearchParams({

                contacto: thisUser
    
            }), {

                method: 'GET',
    
                headers: new Headers({
    
                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),
    
            })
                .then(response => response.json())

                .then(data => {

                    localStorage.setItem('usuarios', JSON.stringify(data));

                })
                .catch(error => console.error('Error al hacer refresh el usuario:', error));

        }

    }

    return (
        <div className='containerGrid'>
            <Header />

            <section className='containerSection'>
                <HeaderDash />
                <div className='containerMain'>
                    {
                        rol === 'admin' ? (
                            <>
                                <div className='deFLexMain'>
                                    <CardsCantidad />
                                    <UsuariosMain />
                                </div>
                                <div className='deFLexMain'>
                                    <ProductosMain />
                                    <InfoUserMain />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='deFLexMain'>
                                    <CardsCantidad />
                                    <InfoUserMain />
                                </div>
                                <div className='deFLexMain'>
                                    <ProductosMain />
                                </div>
                            </>
                        )
                    }
                </div>
            </section>
        </div>
    )
}
