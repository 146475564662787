import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LoginForm.css';
import { useNavigate, } from 'react-router';

import baseURL from '../url';
export default function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailForgot, setEmailForgot] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [sendOTP, setSendOTP] = useState(false);
    const [OTPCode, setOTPCode] = useState('');
    const [mensaje2, setMensaje2] = useState('');
    const navigate = useNavigate();
    const handleLogin = async (e) => {
        
        e.preventDefault();
        
        setMensaje2('Procesando...')
        
        try {

            const pushNotData = (localStorage.getItem('pushNotData')!==null) ? JSON.parse(localStorage.getItem('pushNotData')) : '';

            const pushNotId = (pushNotData) ? pushNotData.id : '';

            const otpcode = (OTPCode) ? OTPCode : '';
            
            const formData = new FormData();

            formData.append('pushNotId', pushNotId);

            formData.append('otpcode', OTPCode);

            formData.append('email', email);
            
            formData.append('contrasena', password);
            
            formData.append('iniciar_sesion', true);

            const response = await fetch(`${baseURL}/loginForm.php`, {
                
                method: 'POST',
                
                body: formData,
            
            });

            if (response.ok) {

                const data = await response.json();

                if (data.mensaje) {

                    toast.success(data.mensaje);
                    
                    setMensaje2('')
                    
                    saveUserToLocalStorage(data.usuario);
                    
                    localStorage.setItem('JWT_Token', data.token);

                    /*if(localStorage.getItem('ubicacion')!==null) {

                        var thisLocation = JSON.parse(localStorage.getItem('ubicacion'));

                        thisLocation.loading = false;

                        thisLocation.latitude = data.usuario.latitud;

                        thisLocation.longitude = data.usuario.longitud;

                        thisLocation.accuracy = data.usuario.accuracy;

                        //localStorage.removeItem('ubicacion');

                        //localStorage.setItem('ubicacion',JSON.stringify(thisLocation));

                    }*/
                    
                    setTimeout(() => {

                        if(data.usuario.rol === 'admin' || data.usuario.rol === 'tienda')

                            navigate("/dashboard");

                        else 
                        
                            navigate("/home");

                    }, 2000);

                } else if (data.error) {

                    setErrorMessage(data.error);

                    //console.log(data.error);

                    toast.error(data.error);

                    setMensaje2('');

                }

            } else {

                throw new Error('Error en la solicitud al servidor');
                
                setMensaje2('')
            
            }
        
        } catch (error) {
            
            console.error('Error:', error.message);
            
            toast.error(error.message);
            
            setMensaje2('')
        
        }
    
    };

    const saveUserToLocalStorage = (user) => {

        /*//console.log('User antes de local storage');

        //console.log(user);

        if(!user) 
        
            return;

        localStorage.setItem('usuarios', JSON.stringify(user));

        //console.log('User despues de local storage');
        
        //console.log(user);

        return;

        */

        // Obtener usuarios existentes del localStorage
        const existingUsers = JSON.parse(localStorage.getItem('usuarios')) || [];

        // Buscar si ya existe un usuario con el mismo idUsuario
        const existingUserIndex = existingUsers.findIndex(u => u.idUsuario === user.idUsuario);

        // Si existe, reemplazar; de lo contrario, agregar a la lista
        if (existingUserIndex !== -1) {
            existingUsers[existingUserIndex] = user;
        } else {
            existingUsers.push(user);
        }

        // Guardar la lista actualizada en localStorage
        localStorage.setItem('usuarios', JSON.stringify(existingUsers));
        //console.log('usuarios guardados', existingUsers)
    };

    const sendMail = async (e) => {

        e.preventDefault();

        setMensaje2('Enviando...');

        try {

            const formData = new FormData();

            formData.append('email', emailForgot);

            formData.append('forgot_password', true);

            const response = await fetch(`${baseURL}/forgotPassword.php`, {

                method: 'POST',

                body: formData,

            });

            if (response.ok) {

                const data = await response.json();

                if (data.mensaje) {

                    toast.success(data.mensaje);

                    setForgotPassword(false);

                    setSendOTP(true);

                } else if (data.error) {

                    setErrorMessage(data.error);

                    toast.error(data.error);

                }

            } else {

                throw new Error('Error en la solicitud al servidor');

            }

        } catch (error) {

            console.error('Error:', error.message);

            toast.error(error.message);

        }

    };

    return (
        <div className='formContain'>
            <ToastContainer />
            <h2>Iniciar Sesión</h2>
            <form onSubmit={handleLogin} className='formAuth'>
                <div className='inputsAuth'>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Email"
                    />
                </div>
                <div className='inputsAuth'>
                    <label htmlFor="password">Contraseña:</label>
                    <div className='deFlexInputs'>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Contraseña"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                </div>
                <button type="submit" className='btnLogin'>
                    Iniciar Sesión
                </button>
            </form>
            <br />
                <span className='sinCuenta' onClick={() => setForgotPassword(true)}>Usar código de un solo uso</span>
                {
                    forgotPassword && (
                        <form onSubmit={sendMail} className='formAuth'>
                            <div className='inputsAuth'>
                            <div className='deFlexInputs'>
                                <input 
                                    type="email" 
                                    placeholder="Ingresa el correo electrónico registrado como usuario" 
                                    value={emailForgot}
                                    onChange={(e) => setEmailForgot(e.target.value)}
                                    required
                                />
                                <button type="submit" className='btnLogin'>Enviar</button>
                            </div>
                            </div>
                        </form>
                    )
                }
                {
                    sendOTP && (
                        <form onSubmit={handleLogin} className='formAuth'>
                            <div className='inputsAuth'>
                            <div className='deFlexInputs'>
                                <input 
                                    type="text" 
                                    placeholder="Ingresa el código de un solo uso" 
                                    value={OTPCode}
                                    onChange={(e) => setOTPCode(e.target.value)}
                                    required
                                />
                                <button type="submit" className='btnLogin'>Validar</button>
                            </div>
                            </div>
                        </form>
                    )
                }
        </div>
    );
}
