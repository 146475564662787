import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './ProductosMain.css'
import baseURL from '../../url';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
export default function ProductosMain() {
    const [productos, setProductos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroTitulo, setFiltroTitulo] = useState('');
    const [filtroCategoria, setFiltroCategoria] = useState('');
    const [filtroCategoria2, setFiltroCategoria2] = useState('');
    const [filtroMasVendido, setFiltroMasVendido] = useState('');

    useEffect(() => {

        getContacto();

        setTimeout(() => {

            //cargarProductos(contacto,rol);

            cargarPedidos(contacto,rol);
            
        },1000);

    }, []);

    const cargarProductos = (contacto,rol) => {

        if(rol === 'admin') 

            contacto=rol;

        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos.reverse().slice(0, 5) || []);
                //console.log(data.productos)
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarPedidos = (contacto,rol) => {

        //console.log(`Get pedidos for contact ${contacto}`);

        let thisContacto = contacto;

        if(contacto === 0 || contacto === '' || contacto === null || contacto === undefined) {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);

            setContacto(dataUser[0].idUsuario);

            //console.log(`Get pedidos for contact ${contacto}`);

            thisContacto = dataUser[0].idUsuario;

        }

        contacto = thisContacto;

        if(rol === 'admin') 

            contacto=rol;

        fetch(`${baseURL}/pedidosGet.php?` + new URLSearchParams({

            contacto: contacto,

            status: 'pedido',

        }), {

            method: 'GET',

            headers: new Headers({

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        }).then(response => response.json())

            .then(data => {

                setPedidos(data.productos || []);

                //console.log(data.productos);

            }).catch(
                
                error => console.error('Error al cargar pedidos:', error)
            );

    };

    const productosFiltrados = pedidos.filter(item => {
        const idMatch = item.idPedido.toString().includes(filtroId);
        const tituloMatch = !filtroTitulo || item.titulo.includes(filtroTitulo);
        const categoriaMatch = !filtroCategoria || item.categoria.includes(filtroCategoria);
        const masVendidoMatch = !filtroMasVendido || item.masVendido.includes(filtroMasVendido);
        const categoriasMatch = !filtroCategoria2 || item.categoria.includes(filtroCategoria2);
        return idMatch && tituloMatch && categoriaMatch && masVendidoMatch && categoriasMatch;
    });

    const getContacto = () => {

        try {

            const dataUser = JSON.parse(localStorage.getItem('usuarios'));

            //console.log(dataUser);

            //console.log(`Id Usuario es ${dataUser[0].idUsuario}`);
    
            setContacto(dataUser[0].idUsuario);

            setRol(dataUser[0].rol);
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const contactar = (item) => {

        const telefono = item.telefono;

        const link = `https://sivendoonline.com/?id=${item.shorturl}`;

        const message = `Hola ${item.cliente}, tu pedido id ${item.idPedido} listo para ser entregado. Este pedido consta de ${item.descripcion} con un importe de $${item.importe}, indicaste pagar con ${item.pago} y para la entrega indicaste ${item.entrega}. Gracias por tu preferencia. ${link} `;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');

    };

    return (

        <>
            <div className='table-containerProductos'>
                <div className='deFlexMore'>
                    <h3>Ultimos {pedidos?.length} pedidos</h3>
                    <Anchor to={`/dashboard/pedidos`} className='logo'>
                        Ver más
                    </Anchor>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Contactar</th>
                            <th>Id Pedido</th>
                            <th>Cliente</th>
                            <th>Imagen</th>
                            <th>Descripcion</th>
                            <th>Precio</th>
                            <th>Descuento</th>
                            <th>Importe</th>
                            <th>Tipo de Pago</th>
                            <th>Entrega</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productosFiltrados.map(item => (
                            <tr key={item.idDetPedido}>
                                <td>
                                    <button className='contactar' onClick={() => contactar(item)}>
                                        <FontAwesomeIcon icon={faWhatsapp} />
                                    </button>
                                    { /*<!-- <button className='eliminar' onClick={() => eliminarPedido(item.idPedido)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button> */ }
                                </td>
                                <td>{item.idPedido}</td>
                                <td>{item.cliente}</td>
                                <td>
                                    {item.imagen ? (
                                        <img src={item.imagen} alt="imagen1" />
                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>
                                <td>{item.descripcion}</td>
                                <td style={{ color: '#008000', textAlign: 'right' }}>${`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                <td style={{ textAlign: 'right' }}>${`${item?.descuento}`}</td>
                                <td style={{ textAlign: 'right' }}>${item.importe}</td>
                                <td>{item.pago}</td>
                                <td>{item.entrega}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            {/*<div className='table-containerProductos'>
                <div className='deFlexMore'>
                    <h3>Ultimos {productos?.length} productos</h3>
                    <Anchor to={`/dashboard/productos`} className='logo'>
                        Ver más
                    </Anchor>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>IdProducto</th>
                            <th>Titulo</th>
                            <th>Descripcion</th>
                            <th>Precio</th>
                            <th>Categoria</th>
                            <th>Imagen 1</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productos.map(item => (
                            <tr key={item.idProducto}>
                                <td>{item.idProducto}</td>
                                <td>{item.titulo}</td>
                                <td>{item.descripcion}</td>
                                <td style={{
                                    color: '#008000',
                                }}>
                                    ${`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </td>

                                <td style={{
                                    color: '#DAA520',

                                }}>  {`${item?.categoria}`}</td>
                                <td>
                                    {item.imagen1 ? (
                                        <img src={item.imagen1} alt="imagen1" />
                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}
        </>

    );
};
