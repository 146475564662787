import React, { useState, useEffect } from 'react';
import './Notifications.css';
//import whatsappIcon from '../../../images/wpp.png';
//import NotifReadIcon from '../../../images/pushnotificationread32x32.png';
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faWhatsappSquare, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBell, faPen } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from 'react-router-dom';
import baseURL from '../../url';
//import OneSignal from 'react-onesignal';
//import { Collapse } from 'react-collapse';
import Modal from 'react-modal';

export default function Notifications() {
    
    const [usuario, setUsuario] = useState({});
    
    const [loading, setLoading] = useState(true);

    const [notifications, setNotifications] = useState([]);

    const [notificationID, setnotificationID] = useState('');

    const [totalnotifications, setTotalNotifications] = useState(0);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const [isOpen, setIsOpen] = useState(false);

    const [productoSeleccionado, setProductoSeleccionado] = useState(null);

    useEffect(() => {

        setTimeout(() => {

            const dataUser = (localStorage.getItem('usuarios') !== null) ? JSON.parse(localStorage.getItem('usuarios')) : {};

            if(dataUser)
            
                setUsuario(dataUser[0]);

            setLoading(false);

        }, 1000);

        setTimeout(() => {

            getNotificationsfromBD();

        }, 3000);

        const interval = setInterval(() => {

            //console.log('Cargando notificaciones desde la bd...');

            getNotificationsfromBD();
            
        }, 30000);
      
        return () => clearInterval(interval);

    }, []);

    /*const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };*/

    const toggleModal = () => {

        setModalIsOpen(!modalIsOpen);

    };

    const openModal = (producto) => {
        
        setProductoSeleccionado(producto);

        if(localStorage.getItem('productoSeleccionado')!==null)

            localStorage.removeItem('productoSeleccionado');

        localStorage.setItem('productoSeleccionado', JSON.stringify(producto));
        
        setModalIsOpen(true);
    
    };

    const closeModal = () => {

        localStorage.removeItem('productoSeleccionado');
        
        setModalIsOpen(false);
    
    };

    const getNotificationsfromBD = async () => {

        const userToken = localStorage.getItem('JWT_Token') ?? null;

            if(!userToken)

                return false;

        const UsuarioData = (localStorage.getItem('usuarios')!==null) ? JSON.parse(localStorage.getItem('usuarios')) : 0;

        //console.log('Data Usuario:', UsuarioData);

        //console.log('Usuario ID:', UsuarioData[0].idUsuario);

        const thisContacto = (UsuarioData) ? UsuarioData[0].idUsuario : 0;

        const pushNotData = (localStorage.getItem('pushNotData')!==null) ? JSON.parse(localStorage.getItem('pushNotData')) : null;

        const notificationID = (pushNotData) ? pushNotData.id : null;

        if(notificationID !== null)

            setnotificationID(notificationID);

        //console.log('Notificaciones:', notificationID);

        //console.log('Usuario:', thisContacto);

        if(notificationID === null || thisContacto === undefined) {

            if(notificationID === null)

                console.error(`No se pudo obtener el id de la notificación ${notificationID}`);

            else if(thisContacto === 0)

                console.error(`No se pudo obtener el id del usuario ${thisContacto}`);

            return;

        }

        fetch(`${baseURL}/notificationsGet.php?`  + new URLSearchParams({

            userId: thisContacto,

            notifID: notificationID

        }), {

            method: 'GET',

            headers: new Headers({

                "Content-Type": "application/json",

                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),

            }),

        })

            .then(response => response.json())

            .then(data => {

                //console.log(data);

                setNotifications(data.notifications.reverse() || []);

                setTotalNotifications(data.notifications.length);

            })

            .catch(error => {

                console.error('Error al cargar notificaciones:', error)
            });

    };

    const markAsReadinBD = async (notificationId) => {

        try {
        
            const response = await fetch(`${baseURL}/notificationsPut.php?`  + new URLSearchParams({

                notifId: notificationId
    
            }), {
        
                method: 'PUT',
        
                headers: {
        
                    "Content-Type": "application/json",

                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
                
                },
                
                body: JSON.stringify({
                
                    notifStatus: 3,
                
                }),
            
            });
        
            if (response.ok) {

                const data = await response.json();

                //console.log('Mark notification as read:', notificationId);

                //console.log(data);

                getNotificationsfromBD();

            } else {
            
                console.error('Error al marcar la notificación como leída');
            
            }

        } catch (error) {

            console.error('Error al marcar la notificación como leída', error);

        }

    };

    /*
    const removeNotificationfromBD = async (notificationId) => {
        
        const options = {
            
            method: 'DELETE',
            
            headers: {
                
                accept: 'application/json',
                
                Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
            
            }
        
        };

        fetch(`${baseURL}/notificationsDelete.php?` + new URLSearchParams({

            notificationId: notificationId

        }), options)
        
        .then(response => response.json())
        
        .then(response => //console.log(response))
        
        .catch(err => console.error(err));

    };
    */

    const openLink = (url) => {

        window.open(url, '_blank');

    };

    return (
        <>
            <Anchor to={``} className='btn-sesion'>
                <FontAwesomeIcon icon={faBell} className='icon' onClick={toggleModal} />
                {
                    (notifications) 

                    ? totalnotifications 

                    : 0
                }
            </Anchor>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                className="modal-cart"
                overlayClassName="overlay-cart"
            >
                <button className="btn" onClick={toggleModal} >
                    <FontAwesomeIcon icon={faArrowLeft} className='iconNotif' />
                </button>
                <div className='deFLexNotif' style={{ overflow:'auto' }}>
                    <ul>
                        {notifications.map((notification) => (
                            <li key={notification.id}>
                                <p>{notification.notiftitle}</p>
                                <p>{notification.notiftext}</p>
                                <div className='deFLexNotifTitle'>
                                    <h4>{notification.titulo}</h4>
                                    <p>{notification.descripcion}</p>
                                    <h4>$ {`${notification?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h4>
                                </div>
                                <div className='deFLexNotifBtn'>
                                    <button onClick={() => openLink(notification.notiflink)}>
                                        <FontAwesomeIcon icon={faWhatsapp} className='iconNotif' />
                                    </button>
                                    <button onClick={() => markAsReadinBD(notification.id)}>
                                        <FontAwesomeIcon icon={faPen} className='iconNotif' />
                                    </button>
                                    {/* <button onClick={() => cargarProducto(notification.notifprod)}>
                                        <FontAwesomeIcon icon={faEye} className='iconNotif' />
                                    </button> */ }
                                 </div>
                                { /*<FontAwesomeIcon icon={['fab', 'whatsapp']} className='icon' /> */ }
                                {/*<button onClick={() => openLink(notification.notiflink)}>
                                    <img src={whatsappIcon} alt="whatsappIcon" />
                                </button>
                                <button onClick={() => markAsReadinBD(notification.id)}>
                                    <img src={NotifReadIcon} alt="NotifReadIcon" />
                                </button>*/}
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal>
            {/*<Collapse isOpened={isOpen}>
                {
                    loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <ul>
                            {notifications.map((notification) => (
                                <li key={notification.id}>
                                    <h2>{notification.headings.en}</h2>
                                    <p>{notification.contents.en}</p>
                                </li>
                            ))}
                        </ul>
                    )
                }
            </Collapse> */ }
            {/*
            <h1>Notificaciones</h1>
            <ul>
                {notifications.map((notification) => (
                <li key={notification.id}>
                    <h2>{notification.headings.en}</h2>
                    <p>{notification.contents.en}</p>
                </li>
                ))}
            </ul>
            */}

            {/*{loading ? (
                <div>cargando</div>
            ) : usuario.idUsuario ? ( // Verifica si hay un usuario válido
                <Anchor to={``} className='btn-sesion'>
                    <FontAwesomeIcon icon={faUser} className='icon' />
                    {usuario.nombre.slice(0.13)}

                </Anchor>
            ) : (
                <Anchor to={``} className='btn-sesion'>
                    <FontAwesomeIcon icon={faUser} className='icon' />
                    Mi Perfil

                </Anchor>
            )} */}
        </>
    );
}
