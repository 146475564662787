import React, { useState } from 'react';
import './NewBanner.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';


export const NewBanner = ({ values, onSubmit, onCancel }) => {
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview, setImagenPreview] = useState(null);
    const [imgbase64, setImgBase64] = useState('');
    const [isImgBase64Selected, setIsImgBase64Selected] = useState(false);
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [contacto, setContacto] = useState('');
    const [rol, setRol] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const getContacto = () => {

        var dataContacto = 0;

        try {

            if(JSON.parse(localStorage.getItem('usuarios'))!== null) {

                const dataUser = JSON.parse(localStorage.getItem('usuarios'));

                setContacto(dataUser[0].idUsuario);

                setRol(dataUser[0].rol);

                dataContacto = (dataUser[0].rol !== 'admin') ? dataUser[0].idUsuario : 0;

            }

            return dataContacto;
    
        } catch (error) {

            //setContacto(1);
    
            console.error('Error:', error.message);
    
            toast.error(error.message);
    
        }

    };

    const toggleModal = () => {

        setModalOpen(!modalOpen);

    };

    const handleImagenChange = (event) => {

        const file = event.target.files[0];

        if (file) {

            const previewURL = URL.createObjectURL(file);

            setImagenPreview(previewURL);

            setIsImageSelected(true);

            const reader = new FileReader();
    
            reader.onloadend = () => {
                    
                setImgBase64(reader.result);

                setIsImgBase64Selected(true);

                //console.log(`Image to Base64`);

                //console.log(imgbase64);

            };
            
            reader.readAsDataURL(file);

            setIsDisabled(false);

        }

    };

    const crear = async () => {

        document.getElementById('imagen').value = '';

        const form = document.getElementById("crearForm");
        
        const formData = new FormData(form);
        
        const resetForm = () => {
            
            form.reset();
            
            setImagenPreview(null);
            
            setIsImageSelected(false);
        
        };
        
        setMensaje('');

        if (!formData.get('imagen')) {
            
            toast.error('Por favor, seleccione una imagen.');
            
            return;
        
        }

        const thisContacto = getContacto();

        formData.append('contacto', thisContacto);

        formData.append('imagen', imgbase64);

        setMensaje('Procesando...');

        try {

            const response = await fetch(`${baseURL}/bannersPost.php`, {
                
                method: 'POST',
                
                body: formData,
                
                headers: new Headers({

                    Authorization: 'Bearer ' + localStorage.getItem('JWT_Token'),
    
                }),

            });

            const data = await response.json();

            if (data.mensaje) {

                setMensaje('');

                resetForm();

                toast.success(data.mensaje);

                //window.location.reload();

            } else if (data.error) {
                
                setMensaje('');
                
                toast.error(data.error);
            
            }
        
        } catch (error) {
            
            console.error('Error:', error);
            
            setMensaje('');
            
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        
        }
    
    };

    return (
        <>
            <form id="crearForm" onSubmit={onSubmit}>
                <div className="flexGrap">
                    <fieldset>
                        <legend>
                            Imagen
                        </legend>
                        <label htmlFor="imagen"> </label>
                        <input
                            type="file"
                            id="imagen"
                            name="imagen"
                            accept="image/webp"
                            onChange={handleImagenChange}
                            required
                        />
                    </fieldset>
                    <span className="small"><a href="https://towebp.io/png-to-webp" target="__blank">Convierta sus fotos e imágenes a tipo webp aquí</a></span>
                </div>
                {isImageSelected &&
                    <div className='previevCategori'>
                        {<img src={imagenPreview} alt="Vista previa" />}
                    </div>
                }
                <div
                    className="swal2-actions"
                    style={{ display: "flex", fontSize: "0.9em" }}
                >
                    {mensaje ? (
                        <button type="button" className='btnLoading' disabled>
                            {mensaje}
                        </button>
                    ) : (
                        <button type="button" onClick={crear} className='btnPost' disabled={isDisabled} >
                            Agregar
                        </button>
                    )}
                </div>
            </form>
        </>
    );
}
